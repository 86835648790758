import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import length from '@turf/length';
import dayjs from 'dayjs';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { IconButton, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import IconReplyAll from '@material-ui/icons/ReplyAll';
import IconDelete from '@material-ui/icons/Delete';

import Pagina from '../adminComponents/Pagina';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(3),
		overflowX: 'auto'
	},
	table: {
		minWidth: 700
	},
	row: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default
		}
	}
});

class ListarRotas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			carregando: true,
			linhas: new Map(),
			page: 0,
			rowsPerPage: 1,
			size: 0
		};
	}

	componentDidMount = () => {
		this.getUidUser = firebase.auth().onAuthStateChanged(user => {
			if (user) {
				this.user_uid = user.uid;
				this.carregarLinhasLista();
			} else {
				this.user_uid = undefined;
			}
		});
	};

	componentWillUnmount() {
		if (typeof this.listenerlinhas === 'function') this.listenerlinhas();

		if (typeof this.getUidUser === 'function') {
			this.getUidUser();
		}
	}

	carregarLinhasLista = () => {
		// const { rowsPerPage, page } = this.state;
		this.listenerlinhas = firebase
			.firestore()
			.collection('linhas')
			.where('excluido', '==', false)
			.orderBy('nome')
			.onSnapshot(querySnapShot => {
				this.setState({
					carregando: false,
					linhas: new Map(
						querySnapShot.docs.map(snap => {
							const data = snap.data();
							return [
								snap.id,
								{
									...data,
									distancia: length(JSON.parse(data.rota), {
										units: 'kilometers'
									}).toFixed(1),
									qtd_pontos: data.pontos.length
								}
							];
						})
					)
				});
			});
	};

	toggleStatusLinha = id => {
		let { linhas } = this.state;
		let linha = linhas.get(id);
		linha.ativo = !linha.ativo;
		linhas.set(linha.id, linha);
		firebase
			.firestore()
			.collection('linhas')
			.doc(id)
			.set(linha);
	};

	duplicarRota = id => {
		if (window.confirm('Deseja realmente duplicar está linha ?')) {
			firebase
				.firestore()
				.collection('linhas')
				.doc(id)
				.get()
				.then(snapshotLinha => {
					let linhaRef = firebase
						.firestore()
						.collection('linhas')
						.doc();
					let linha = {
						...snapshotLinha.data(),
						id: linhaRef.id
					};
					linha.nome += 'Réplica';
					linha.ativo = false;
					linhaRef.set(linha);
					this.carregarLinhasLista();
				});
		}
	};
	deletarLinha = id => {
		if (window.confirm('Deseja Realmente deletar está linha ?')) {
			firebase
				.firestore()
				.collection('linhas')
				.doc(id)
				.update({
					excluido: true,
					ativo: false
				});
		}
	};

	render() {
		const { classes } = this.props;
		const { linhas, carregando } = this.state;
		return (
			<Pagina>
				<Typography style={{ fontWeight: 'bold' }} variant="h5" color="inherit">
					Rotas
				</Typography>

				<Grid container>
					<Grid item xs={6} lg={6} />

					<Grid item xs={6} lg={6}>
						<Button
							variant="contained"
							onClick={() => this.props.history.replace('/Rota')}
							style={{ float: 'right', margin: '0 10px' }}
						>
							Adicionar rota
						</Button>
						<Button
							variant="contained"
							onClick={() => this.props.history.replace('/VisaoGeralRotas')}
							style={{ float: 'right', margin: '0 10px' }}
						>
							Visão Geral
						</Button>
					</Grid>
				</Grid>

				<br />
				<Divider />
				<Paper className={classes.root}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Nome da rota</TableCell>
								<TableCell>Cor</TableCell>
								<TableCell>Distancia (KM)</TableCell>
								<TableCell>Nº Pontos</TableCell>
								<TableCell> Data Cadastro </TableCell>
								<TableCell> Ativo </TableCell>
								<TableCell> Ações </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{linhas.size === 0 && carregando && (
								<TableRow className={classes.row}>
									<TableCell style={{ padding: '1em 2em' }} colSpan={5}>
										Carregando...
									</TableCell>
								</TableRow>
							)}
							{[...linhas.values()].map(linha => {
								return (
									<TableRow className={classes.row} key={linha.id}>
										<TableCell>{linha.nome} </TableCell>
										<TableCell>
											<div
												style={{
													width: '30px',
													height: '30px',
													background: linha.cor
												}}
											/>
										</TableCell>
										<TableCell>{linha.distancia}</TableCell>
										<TableCell>{linha.qtd_pontos}</TableCell>
										<TableCell>
											{dayjs(
												new Date(linha.data_cadastro.seconds * 1000)
											).format('DD/MM/YYYY')}
										</TableCell>
										<TableCell>
											<Switch
												checked={linha.ativo}
												onChange={() => this.toggleStatusLinha(linha.id)}
												value={linha.ativo}
											/>
										</TableCell>
										<TableCell>
											<Link to={`/Rota/${linha.id}`}>
												<IconButton>
													<Icon>edit_icon</Icon>
												</IconButton>
											</Link>

											<IconButton onClick={() => this.duplicarRota(linha.id)}>
												<IconReplyAll />
											</IconButton>
											<IconButton onClick={() => this.deletarLinha(linha.id)}>
												<IconDelete />
											</IconButton>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
					{/* <TablePagination
					rowsPerPageOptions={[1, 2]}
					component="div"
					count={this.state.size}
					page={this.state.page}
					rowsPerPage={rowsPerPage}
					backIconButtonProps={{
						'aria-label': 'Previous Page'
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page'
					}}
					onChangePage={(e, page) => {
						this.setState({ page });
						this.carregarLinhasLista();
					}}
					onChangeRowsPerPage={e => {
						this.setState({ rowsPerPage: e.target.value });
						this.carregarLinhasLista();
					}}
				/> */}
				</Paper>
			</Pagina>
		);
	}
}

ListarRotas.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(ListarRotas));
