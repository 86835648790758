import React from 'react';
import { Layer, Feature } from 'react-mapbox-gl';
import PontoIcon from '../images/icone-ponto-onibus.svg';
import PontoIconSelected from '../images/pontoIconSelect.svg';

let img = new Image(50, 50);
img.src = PontoIcon;

let img2 = new Image(50, 50);
img2.src = PontoIconSelected;

const LayerPontos = ({ id, array, onClick, type = 1, ...props }) => {
    if (!id) {
        throw new Error('atributo id obrigatorio em LayerPontos.');
    }

    if (array instanceof Map) {
        array = [...array.values()];
    } else if (!Array.isArray(array) && array instanceof Object) {
        array = [array];
    }

    return (
        <React.Fragment>
            {array && (
                <Layer
                    {...props}
                    type="symbol"
                    layout={{
                        'icon-image': id,
                        'icon-size': [
                            'interpolate',
                            ['exponential', 1],
                            ['zoom'],
                            1,
                            1,
                            11,
                            0.5,
                            20,
                            0.65
                        ],
                        'symbol-avoid-edges': true,
                        'icon-allow-overlap': true
                    }}
                    images={[id, type === 1 ? img : img2]}
                >
                    {array.map((point, i) => (
                        <Feature
                            key={i}
                            onClick={() => onClick(point,i)}
                            coordinates={[point.longitude, point.latitude]}
                            properties={point.properties}
                        />
                    ))}
                </Layer>
            )}
        </React.Fragment>
    );
};

export default LayerPontos;
