const dayjs = require('dayjs');

export const isDayWeek = () => {
	let nameDayOfWeek = dayjs().format('dddd');
	return nameDayOfWeek !== 'Saturday' || nameDayOfWeek !== 'Sunday';
};

export const getDayName = () => dayjs().format('dddd');

export const isSunday = () => dayjs().format('dddd') === 'Sunday';
export const isSaturday = () => dayjs().format('dddd') === 'Saturday';

export const jaPassou = _horaBus => {
	_horaBus = _horaBus.split(':');
	const horaBus = dayjs()
		.set('hour', _horaBus[0])
		.set('minute', _horaBus[1])
		.set('second', '0');
	const diff = horaBus.diff(dayjs(), 'minute');
	return diff <= -8 ? true : false;
};
/**
 *
 * @param {integer} IntegerBinary hh:mm = Integer -> Dias;
 */
export const daysByBinary = value => {
	let splitedBinary = ((255 - value) >>> 0)
		.toString(2)
		.split('')
		.reverse();
	let dias = {
		domingo: { value: 1, active: true }, //domingo
		segunda: { value: 2, active: true }, //segunda
		terca: { value: 4, active: true }, //terça
		quarta: { value: 8, active: true }, //quarta
		quinta: { value: 16, active: true }, //quinta
		sexta: { value: 32, active: true }, //sexta
		sabado: { value: 64, active: true }, // sabado
		feriado: { value: 128, active: true } //feriado
	};
	return Object.keys(dias).reduce((prev, next, i) => {
		prev[next] = {
			active: !parseInt(splitedBinary[i]),
			value: dias[next].value
		};
		return prev;
	}, {});
};
