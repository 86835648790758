import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

function LoadingComponent() {
    return (
        <div>
            <CircularProgress />
        </div>
    );
}

class Loading extends Component {
    styles = theme => {
        const { props } = this;

        return {
            progress: {
                margin: Array.isArray(props.m)
                    ? `${theme.spacing(1) * props.m[0]}px ${theme.spacing(1) *
                          props.m[1]}px`
                    : theme.spacing(1) * props.m
            }
        };
    };

    div = props => (
        <div className={props.classes.progress}>{props.children}</div>
    );

    Styled = withStyles(this.styles)(this.div);

    render() {
        return (
            <this.Styled>
                <LoadingComponent />
            </this.Styled>
        );
    }
}

export default Loading;
