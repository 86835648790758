import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';

import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withRouter } from 'react-router-dom';
import * as firebase from 'firebase/app';

const styles = theme => ({
	grow: {
		flexGrow: 1
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1
	},
	logo: { padding: theme.spacing(1), textAlign: 'center', color: '#444444' },
	menuButton: {
		marginLeft: 8,
		marginRight: 20
	}
});

class MenuAppBar extends Component {
	state = {
		openDrawer: false,
		anchorEl: null,
		auth: true
	};

	handleChange = event => {
		this.setState({ auth: event.target.checked });
	};

	handleMenu = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	logoff = () => {
		firebase
			.auth()
			.signOut()
			.then(() => this.props.history.replace('/'))
			.catch(() => window.close());
	};

	render() {
		const { classes, items, onClickItemMenu, title } = this.props;
		const { openDrawer } = this.state;
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		return (
			<>
				<AppBar position="sticky" className={classes.appBar}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="Open drawer"
							onClick={() =>
								this.setState({
									openDrawer: !this.state.openDrawer
								})
							}
							className={classNames(
								classes.menuButton,
								openDrawer && classes.hide
							)}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							variant="h6"
							color="inherit"
							noWrap
							className={classes.grow}
						>
							{title}
						</Typography>
						<IconButton
							aria-owns={open ? 'menu-appbar' : undefined}
							aria-haspopup="true"
							onClick={this.handleMenu}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
							open={open}
							onClose={this.handleClose}
						>
							{/* <MenuItem onClick={this.handleClose}>Meu Perfil</MenuItem> */}
							<MenuItem onClick={this.logoff}>Sair</MenuItem>
						</Menu>
					</Toolbar>
				</AppBar>
				<Drawer
					open={this.state.openDrawer}
					onClose={() => this.setState({ openDrawer: !this.state.openDrawer })}
				>
					<div
						tabIndex={0}
						role="button"
						onClick={() =>
							this.setState({
								openDrawer: !this.state.openDrawer
							})
						}
						onKeyDown={() =>
							this.setState({
								openDrawer: !this.state.openDrawer
							})
						}
					>
						{items.map((groupItems, i) => (
							<React.Fragment key={i}>
								<div className={classes.logo}>{groupItems.name}</div>
								{groupItems.items.map(item => (
									<List key={item.title}>
										<ListItem onClick={() => onClickItemMenu(item.link)} button>
											<ListItemIcon>
												{item.icon ? item.icon : <InboxIcon />}
											</ListItemIcon>
											<ListItemText primary={item.title} />
										</ListItem>
									</List>
								))}
								<Divider />
							</React.Fragment>
						))}
					</div>
				</Drawer>
			</>
		);
	}
}

MenuAppBar.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(MenuAppBar));
