import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
    card: {
        maxWidth: '100%',
        height: '100%'
    },
    media: {
        // ⚠️ object-fit is not supported by IE 11.
        objectFit: 'cover',
        height: '400px'
    },
    actions: {
        display: 'flex'
    }
});

const LoadingComponent = withStyles(theme => ({
    progress: {
        margin: theme.spacing(5)
    }
}))(_LoadingComponent);

function _LoadingComponent(props) {
    const { classes } = props;
    return (
        <div>
            <CircularProgress className={classes.progress} />
        </div>
    );
}

class CardPonto extends Component {
    render() {
        const {
            classes,
            imageSrc,
            imageAlt = '',
            imageTitle = '',
            title,
            loading,
            footer
        } = this.props;
        return (
            <Card className={classes.card}>
                {!loading && imageSrc && (
                    <CardMedia
                        component="img"
                        alt={imageAlt}
                        className={classes.media}
                        height="140"
                        image={imageSrc}
                        title={imageTitle}
                    />
                )}

                {loading && <LoadingComponent />}

                <CardContent>
                    <h1 style={{ margin: 0 }}>{title}</h1>
                    <div style={{ margin: 0 }}>{this.props.children}</div>
                </CardContent>
                <CardActions>{footer}</CardActions>
            </Card>
        );
    }
}

CardPonto.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CardPonto);
