import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import IconVisibility from '@material-ui/icons/Visibility';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import IconClear from '@material-ui/icons/Clear';
import Loading from '../adminComponents/Loading';

import Pagina from '../adminComponents/Pagina';

const rootStyles = {
    width: '100%',
    marginTop: '30px',
    overflowX: 'auto'
};

const tableStyles = {
    minWidth: '700px'
};

class ListarFeedBack extends Component {
    state = {
        maxPerPage: 25,
        list: new Map(),
        lastVisible: {},
        previusStartAfter: [],
        feedback: false,
        loadingModal: false,
        loading: true
    };

	componentDidMount() {
		const { maxPerPage } = this.state;
		var first = firebase
			.firestore()
			.collection('feedback')
			.orderBy('data_cadastro')
			.limit(maxPerPage);

        first.get().then(documentSnapshots => {
            var lastVisible =
                documentSnapshots.docs[documentSnapshots.docs.length - 1];

            this.setState({
                lastVisible: lastVisible,
                list: new Map(
                    documentSnapshots.docs.map(snap => [snap.id, snap.data()])
                ),
                previusStartAfter: [documentSnapshots.docs[0]],
                loading: false
            });
        });
    }

	nextPage = () => {
		const { maxPerPage, lastVisible } = this.state;
		this.setState({
			loading: true
		});
		firebase
			.firestore()
			.collection('feedback')
			.orderBy('data_cadastro')
			.startAfter(lastVisible)
			.limit(maxPerPage)
			.get()
			.then(documentSnapshots => {
				if (documentSnapshots.docs.length > 0) {
					const lastVisible =
						documentSnapshots.docs[documentSnapshots.docs.length - 1];
					this.setState({
						previusStartAfter: [
							...this.state.previusStartAfter,
							documentSnapshots.docs[0]
						],
						lastVisible: lastVisible,
						list: new Map(
							documentSnapshots.docs.map(snap => [snap.id, snap.data()])
						),
						loading: false
					});
				}
				this.setState({
					loading: false
				});
			});
	};

    previusPage = () => {
        let { previusStartAfter } = this.state;
        const startAfter = previusStartAfter[previusStartAfter.length - 2];
        if (startAfter) {
            this.setState({
                loading: true
            });
            previusStartAfter = previusStartAfter.slice(
                0,
                previusStartAfter.length - 2
            );

			firebase
				.firestore()
				.collection('feedback')
				.orderBy('data_cadastro')
				.startAt(startAfter)
				.limit(1)
				.get()
				.then(documentSnapshots => {
					if (documentSnapshots.docs.length > 0) {
						const lastVisible =
							documentSnapshots.docs[documentSnapshots.docs.length - 1];

                        this.setState({
                            previusStartAfter: [
                                ...this.state.previusStartAfter,
                                documentSnapshots.docs[0]
                            ],
                            lastVisible: lastVisible,
                            list: new Map(
                                documentSnapshots.docs.map(snap => [
                                    snap.id,
                                    snap.data()
                                ])
                            )
                        });
                    }
                    this.setState({
                        loading: false
                    });
                });
            this.setState({
                previusStartAfter,
                loading: false
            });
        }
    };

	openFeedBack = feedback_id => {
		this.setState({
			loadingModal: true
		});
		firebase
			.firestore()
			.collection('feedback')
			.doc(feedback_id)
			.get()
			.then(snap => {
				let feedback = snap.data();
				feedback.visualizado = true;
				firebase
					.firestore()
					.collection('feedback')
					.doc(feedback_id)
					.set(feedback)
					.then(() => {
						this.setState({
							feedback,
							loadingModal: false
						});
					});
			});
	};

    render() {
        const { list, loading, feedback, loadingModal } = this.state;
        return (
            <Pagina>
                <h2>FeedBack</h2>
                <Divider />
                <Paper style={rootStyles}>
                    <Table style={tableStyles}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Conteúdo</TableCell>
                                <TableCell>Tipo</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableCell
                                        style={{ padding: '1em 2em' }}
                                        colSpan={5}
                                    >
                                        Carregando...
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading &&
                                [...list.keys()].map(keyFeedback => {
                                    const feedback = list.get(keyFeedback);
                                    return (
                                        <TableRow key={keyFeedback}>
                                            <TableCell>
                                                {feedback.mensagem.slice(0, 25)}
                                                ...{' '}
                                            </TableCell>
                                            <TableCell>
                                                {feedback.tipo}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        this.openFeedBack(
                                                            keyFeedback
                                                        )
                                                    }
                                                >
                                                    <IconVisibility
                                                        style={{
                                                            color: feedback.visualizado
                                                                ? 'red'
                                                                : 'green'
                                                        }}
                                                    />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    {!loading && (
                        <Grid container>
                            <Grid item xs={3} lg={3} />
                            <Grid item xs={3} lg={3}>
                                <Button
                                    variant="contained"
                                    style={{ float: 'right' }}
                                    onClick={this.previusPage}
                                >
                                    Anterior
                                </Button>
                            </Grid>
                            <Grid item xs={3} lg={3}>
                                <Button
                                    variant="contained"
                                    onClick={this.nextPage}
                                >
                                    Próximo
                                </Button>
                            </Grid>
                            <Grid item xs={3} lg={3} />
                        </Grid>
                    )}
                </Paper>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={loadingModal || !!feedback}
                >
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: 'white',
                            margin: '10vh 0',
                            padding: '0em 0em 0em'
                        }}
                    >
                        <div
                            style={{
                                background: '#3f51b5',
                                width: '100%',
                                padding: '1em',
                                color: 'white'
                            }}
                        >
                            <Grid container>
                                <Grid item xs={6} lg={6}>
                                    <Typography
                                        style={{ marginTop: '10px' }}
                                        color="inherit"
                                        variant="h6"
                                        noWrap
                                    >
                                        FeedBack
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} lg={5} />
                                <Grid item xs={1} lg={1}>
                                    <IconButton
                                        aria-owns={
                                            this.props.open
                                                ? 'menu-appbar'
                                                : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={() =>
                                            this.setState({
                                                feedback: false,
                                                loadingModal: false
                                            })
                                        }
                                        color="inherit"
                                        style={{ float: 'right' }}
                                    >
                                        <IconClear />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </div>
                        <br />
                        <div style={{ height: '50vh' }}>
                            {!feedback && loadingModal && (
                                <div>
                                    <span
                                        style={{
                                            position: 'relative',
                                            top: '35px',
                                            left: '85px'
                                        }}
                                    >
                                        Carregando...
                                    </span>
                                    <Loading m={[0, 3]} />
                                    <br />
                                </div>
                            )}
                            {feedback && !loadingModal && (
                                <div style={{ padding: '1em' }}>
                                    {feedback.mensagem}
                                </div>
                            )}
                        </div>
                    </div>
                </Modal>
            </Pagina>
        );
    }
}

export default ListarFeedBack;
