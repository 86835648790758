import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
	apiKey: `${process.env.REACT_APP_FB_APIKEY}`,
	authDomain: `${process.env.REACT_APP_FB_AUTHDOMAIN}`,
	databaseURL: `${process.env.REACT_APP_FB_DATABASEURL}`,
	projectId: `${process.env.REACT_APP_FB_PROJECTID}`,
	storageBucket: `${process.env.REACT_APP_FB_STORAGEBUCKET}`,
	messagingSenderId: `${process.env.REACT_APP_FB_MESSAGINGSENDERID}`
};

firebase.initializeApp(config);

// firebase.firestore().enablePersistence();
export default firebase;
