import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper
} from '@material-ui/core';
import 'mapbox-gl/dist/mapbox-gl.css';

import Grid from '@material-ui/core/Grid';
import pontoIcon from '../images/icone-ponto-onibus.svg';
import { Divider } from '@material-ui/core';
import LayerLinha from '../components/LayerLinha';
import StylesMapBox from "../configs/style_mapbox.json";

import Pagina from '../adminComponents/Pagina';

const MapBox = ReactMapboxGl({
    accessToken:
        'pk.eyJ1IjoiY3Jvc3NrcGl4ZWwiLCJhIjoiY2pjaHR2eTZmMDlueTMzbnNqNXdiczFvNyJ9.8BD7dd_uSkBW_gynYydR_A',
    refreshExpiredTiles: false
});

class VisaoGeralRotas extends Component {
    state = {
        localizacao: null,
        pontos: new Map(),
        linhas: new Map(),
        mapaCentro: [-52.372141, -24.04541],
        zoom: [14],
        selected: '',
        selectedImage: '',
        posicaoPontoParaUpdate: {},
        loading: true
    };

    componentDidMount() {
        this.pontoImg = new Image(24, 24);
        this.pontoImg.src = pontoIcon;

        // this.pontoImgSelect = new Image(50, 50);
        // this.pontoImgSelect.src = pontoIconSelect;
        this.getUidUser = firebase.auth().onAuthStateChanged(user => {
            this.user_uid = user.uid;
            this.carregarPontos();
            this.carregarLinhas();
        });
    }

    componentWillUnmount() {
        if (typeof this.getUidUser === 'function') this.getUidUser();
        if (typeof this.pontos === 'function') this.pontos();
        if (typeof this.linhas === 'function') this.linhas();
    }

    carregarPontos = () => {
        this.pontos = firebase
            .firestore()
            .collection('pontos')
            .onSnapshot(querySnapshot =>
                this.setState({
                    pontos: new Map(
                        querySnapshot.docs.map(snapshot => [
                            snapshot.id,
                            {
                                ...snapshot.data(),
                                id: snapshot.id,
                                visibility: true
                            }
                        ])
                    ),
                    loading: false
                })
            );
    };

	carregarLinhas = () => {
		this.linhas = firebase
			.firestore()
			.collection('linhas')
			.where('excluido', '==', false)
			.where('ativo', '==', true)
			.orderBy('nome', 'asc')
			.onSnapshot(snaps => {
				this.setState({
					linhas: new Map(
						snaps.docs.map(snap => {
							let linha = snap.data();
							linha.rota = JSON.parse(linha.rota);
							return [linha.id, linha];
						})
					)
				});
			});
	};

    render() {
        const { pontos, linhas, loading } = this.state;
        return (
            <Pagina>
                <h2>Visão Geral Rotas</h2>
                <Divider />

                <Paper>
                    <Grid container>
                        <Grid item xs={12} lg={8}>
                            <MapBox
                                style={StylesMapBox} // eslint-disable-next-line
                                containerStyle={{
                                    height: '700px',
                                    width: '100%'
                                }}
                                center={this.state.mapaCentro}
                                onZoomEnd={map => {
                                    if (
                                        this.state.zoom[0] !== map.getZoom() &&
                                        !this.moving
                                    ) {
                                        this.setState({
                                            zoom: [map.getZoom()]
                                        });
                                    }
                                }}
                                onDragEnd={map => {
                                    const novoCentro = map.getCenter();

                                    if (
                                        this.state.mapaCentro[0] !==
                                            novoCentro.lng ||
                                        (this.state.mapaCentro[1] !==
                                            novoCentro.lat &&
                                            !this.moving)
                                    ) {
                                        this.setState({
                                            mapaCentro: [
                                                novoCentro.lng,
                                                novoCentro.lat
                                            ]
                                        });
                                    }
                                }}
                                onMove={map => {
                                    this.moving = map.isMoving();
                                }}
                                onMoveEnd={map => {
                                    this.moving = map.isMoving();
                                }}
                                zoom={this.state.zoom}
                            >
                                {linhas.size > 0 &&
                                    [...linhas.values()].map((linha, i) => {
                                        return (
                                            linha.rota && (
                                                <LayerLinha
                                                    key={i}
                                                    rota={linha.rota}
                                                    color={linha.cor}
                                                    width={6}
                                                />
                                            )
                                        );
                                    })}

                                {pontos.size > 0 ? (
                                    <React.Fragment>
                                        <Layer
                                            id="PonotosLayer"
                                            type="symbol"
                                            layout={{
                                                'icon-image': 'PontoIcon',
                                                'icon-allow-overlap': true,
                                                'icon-size': [
                                                    'interpolate',
                                                    ['exponential', 1],
                                                    ['zoom'],
                                                    0,
                                                    1,
                                                    10,
                                                    0.5,
                                                    21,
                                                    1
                                                ],
                                                'symbol-avoid-edges': true
                                            }}
                                            images={[
                                                'PontoIcon',
                                                this.pontoImg
                                            ]}
                                            icon-anchor="center"
                                        >
                                            {[...pontos.values()].map(ponto => {
                                                return (
                                                    <Feature
                                                        key={ponto.id}
                                                        // onClick={() => this.selectPonto(ponto)}
                                                        coordinates={
                                                            ponto.visibility
                                                                ? [
                                                                      ponto.longitude,
                                                                      ponto.latitude
                                                                  ]
                                                                : [0, 0]
                                                        }
                                                    />
                                                );
                                            })}
                                        </Layer>
                                    </React.Fragment>
                                ) : null}
                            </MapBox>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container>
                                <Paper
                                    style={{ minWidth: 500, minHeight: 700 }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Nome da Linha
                                                </TableCell>
                                                <TableCell> Cor </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {linhas.size === 0 && (
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            padding: '1em 2em'
                                                        }}
                                                        colSpan={4}
                                                    >
                                                        {loading
                                                            ? 'Carregando...'
                                                            : 'Nenhum registro encontrado.'}
                                                    </TableCell>
                                                </TableRow>
                                            )}

                                            {[...linhas.values()].map(
                                                (linha, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell>
                                                            {linha.nome}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div
                                                                style={{
                                                                    height: 25,
                                                                    width: 25,
                                                                    background:
                                                                        linha.cor
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container />
            </Pagina>
        );
    }
}

export default VisaoGeralRotas;
