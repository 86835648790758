import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { SnackbarProvider, withSnackbar } from 'notistack';
import axios from 'axios';
import VMasker from 'vanilla-masker';
import IconClear from '@material-ui/icons/Clear';
import IconDelete from '@material-ui/icons/Delete';

const rootStyles = {
    width: '100%',
    marginTop: '30px',
    overflowX: 'auto'
};

const tableStyles = {
    minWidth: '700px'
};

class CriarDispositivoModal extends Component {
    state = {
        fieldsError: {
            nome: false,
            identificador_traccar: false,
            telefone_responsavel: false,
            nome_responsavel: false
        },
        nome: '',
        identificador_traccar: '',
        telefone_responsavel: '',
        nome_responsavel: '',
        loading: false
    };

    resetState = () =>
        this.setState({
            fieldsError: {
                nome: false,
                identificador_traccar: false,
                telefone_responsavel: false,
                nome_responsavel: false
            }
        });

    onSubmit = e => {
        e.preventDefault();
        this.resetState();
        const {
            nome,
            identificador_traccar,
            telefone_responsavel,
            nome_responsavel
        } = this.state;
        const { onClose, onCreate } = this.props;

        this.setState({
            fieldsError: {
                ...this.state.fields,
                identificador_traccar:
                    identificador_traccar.indexOf(' ') !== -1,
                nome: nome.length > 3,
                telefone_responsavel: telefone_responsavel.length === 6,
                nome_responsavel: nome_responsavel.length > 3
            }
        });

        if (
            !(
                identificador_traccar.indexOf(' ') !== -1 &&
                nome.length > 3 &&
                telefone_responsavel.length === 16 &&
                nome_responsavel.length > 3
            )
        ) {
            this.setState({
                loading: true
            });

			axios
				.get(
					`https://webservice.olaonibus.com.br/api/devices/count?_where=(uniqueid,eq,${identificador_traccar})&q=incubador`
				)
				.then(response => {
					if (response.data[0].no_of_rows === 0) {
						axios
							.post(
								'https://webservice.olaonibus.com.br/api/devices?q=incubador',
								{
									name: nome,
									uniqueid: identificador_traccar,
									attributes: '{}',
									phone: telefone_responsavel,
									model: '',
									contact: telefone_responsavel,
									category: '',
									disabled: ''
								}
							)
							.then(responseDevice => {
								axios
									.post(
										`https://webservice.olaonibus.com.br/api/user_device?q=incubador`,
										{
											userid: 1,
											deviceid: responseDevice.data.insertId
										}
									)
									.then(responseUserDevice => {
										if (responseUserDevice.data.affectedRows === 1) {
											const deviceRef = firebase
												.firestore()
												.collection('admin-dispositivos')
												.doc();
											firebase.auth().onAuthStateChanged(user => {
												deviceRef
													.set({
														id: deviceRef.id,
														name: nome,
														identificador_traccar: identificador_traccar,
														device_id_traccar: responseDevice.data.insertId,
														user_uid: user.uid,
														data_cadastro: firebase.firestore.FieldValue.serverTimestamp(),
														ativo: true
													})
													.then(() => {
														this.setState({
															fieldsError: {
																nome: false,
																identificador_traccar: false,
																telefone_responsavel: false,
																nome_responsavel: false
															},
															nome: '',
															identificador_traccar: '',
															telefone_responsavel: '',
															nome_responsavel: '',
															loading: false
														});

                                                            this.props.enqueueSnackbar(
                                                                'Dispositivo cadastrado com sucesso',
                                                                {
                                                                    variant:
                                                                        'success',
                                                                    autoHideDuration: 2500
                                                                }
                                                            );
                                                            onClose();
                                                            onCreate();
                                                        });
                                                });
                                        }
                                    });
                            })
                            .catch(err => {
                                this.props.enqueueSnackbar(
                                    'Ocorreu um erro interno.',
                                    {
                                        variant: 'warning'
                                    }
                                );
                                this.setState({ loading: false });
                            });
                    } else {
                        this.props.enqueueSnackbar(
                            'Este Identificador já está em uso.',
                            {
                                variant: 'warning'
                            }
                        );
                        this.setState({
                            identificador_traccar: '',
                            fieldsError: {
                                ...this.state.fieldsError,
                                identificador_traccar: true
                            },
                            loading: false
                        });
                    }
                });
        }
    };

    onChange = e =>
        this.setState({
            [e.target.id]: e.target.value,
            fieldsError: {
                ...this.state.fieldsError,
                [e.target.id]: false
            }
        });

    render() {
        const { fieldsError, loading } = this.state;

        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.open}
                onClose={this.handleClose}
            >
                <div
                    style={{
                        width: '100%',
                        backgroundColor: 'white',
                        margin: '10vh 0',
                        padding: '0em 0em 0em'
                    }}
                >
                    <div
                        style={{
                            background: '#3f51b5',
                            width: '100%',
                            padding: '1em',
                            color: 'white'
                        }}
                    >
                        <Grid container>
                            <Grid item xs={6} lg={6}>
                                <Typography color="inherit" variant="h6" noWrap>
                                    Dispositivo
                                </Typography>
                            </Grid>
                            <Grid item xs={5} lg={5} />
                            <Grid item xs={1} lg={1}>
                                <IconButton
                                    aria-owns={
                                        this.props.open
                                            ? 'menu-appbar'
                                            : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={this.props.onClose}
                                    color="inherit"
                                    style={{ float: 'right' }}
                                >
                                    <IconClear />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>

                    <br />

                    <form
                        style={{ padding: '1em 2em' }}
                        onSubmit={this.onSubmit}
                    >
                        <Typography variant="h6" id="modal-title">
                            Informações do dispositivo
                        </Typography>

                        <FormControl
                            margin="normal"
                            // error={this.state.fields.nome.error}
                            required
                            fullWidth
                        >
                            <InputLabel htmlFor="email">
                                Nome do dispositivo
                            </InputLabel>
                            <Input
                                id="nome"
                                name="nome"
                                onChange={this.onChange}
                                autoComplete="nome"
                                value={this.state.nome}
                                placeholder="Nome do dispositivo Ex: Moto G1 de Fulano"
                                required
                                autoFocus
                            />
                        </FormControl>

                        <FormControl
                            margin="normal"
                            error={fieldsError.identificador_traccar}
                            required
                            fullWidth
                        >
                            <InputLabel htmlFor="email">
                                Identificador para sincronização
                            </InputLabel>
                            <Input
                                onChange={this.onChange}
                                required
                                value={this.state.identificador_traccar}
                                placeholder="Digite o identificador do dispositivo sem espaços ( )"
                                id="identificador_traccar"
                                name="identificador_traccar"
                                autoComplete="identificador_traccar"
                            />
                        </FormControl>
                        <br />
                        <br />

                        <Typography variant="h6" id="modal-title">
                            Dados do responsável do dispositivo
                        </Typography>
                        <Grid container>
                            <Grid item lg={5} xs={5}>
                                <FormControl
                                    margin="normal"
                                    // error={this.state.fields.nome.error}
                                    required
                                    fullWidth
                                >
                                    <InputLabel htmlFor="telefone_responsavel">
                                        Nome do responsável
                                    </InputLabel>
                                    <Input
                                        onChange={this.onChange}
                                        required
                                        value={this.state.nome_responsavel}
                                        id="nome_responsavel"
                                        name="nome_responsavel"
                                        autoComplete="nome_responsavel"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item lg={1} xs={1} />
                            <Grid item lg={5} xs={5}>
                                <FormControl
                                    margin="normal"
                                    required
                                    error={
                                        this.state.fieldsError
                                            .telefone_responsavel
                                    }
                                    fullWidth
                                >
                                    <InputLabel htmlFor="telefone_responsavel">
                                        Telefone do responsável
                                    </InputLabel>
                                    <Input
                                        onChange={e => {
                                            this.setState({
                                                [e.target
                                                    .id]: VMasker.toPattern(
                                                    e.target.value,
                                                    '(99) 9 9999-9999'
                                                )
                                            });
                                        }}
                                        value={this.state.telefone_responsavel}
                                        id="telefone_responsavel"
                                        name="telefone_responsavel"
                                        autoComplete="telefone_responsavel"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br />
                        <Button
                            disabled={loading}
                            fullWidth
                            type="submit"
                            variant="contained"
                        >
                            {!loading ? 'Cadastrar' : 'Aguarde ...'}
                        </Button>
                    </form>
                </div>
            </Modal>
        );
    }
}

const DispositivoModal = withSnackbar(CriarDispositivoModal);

class _ListarDispositivos extends Component {
    state = {
        maxPerPage: 999,
        list: new Map(),
        lastVisible: {},
        previusStartAfter: [],
        modalOpen: false,
        fields: {},
        loading: true
    };

    componentDidMount() {
        this.getUidUser = firebase.auth().onAuthStateChanged(user => {
            this.setState({
                user_uid: user.uid
            });
            this.loadPage();
        });
    }

    componentWillUnmount() {
        if (typeof this.getUidUser === 'function') {
            this.getUidUser();
        }
    }

    loadPage = () => {
        const { maxPerPage } = this.state;

		var first = firebase
			.firestore()
			.collection('admin-dispositivos')
			.where('ativo', '==', true)
			.where('user_uid', '==', this.state.user_uid)
			.limit(maxPerPage);

        first.get().then(documentSnapshots => {
            var lastVisible =
                documentSnapshots.docs[documentSnapshots.docs.length - 1];

            this.setState({
                lastVisible: lastVisible,
                list: new Map(
                    documentSnapshots.docs.map(snap => [snap.id, snap.data()])
                ),
                previusStartAfter: [documentSnapshots.docs[0]],
                loading: false
            });
        });
    };

	deleteDevice = idRef => {
		if (window.confirm('Deseja realmente excluir este dispositivo ?')) {
			firebase
				.firestore()
				.collection('admin-dispositivos')
				.doc(idRef)
				.get()
				.then(snap => {
					let device = snap.data();
					device.ativo = false;
					firebase
						.firestore()
						.collection('admin-dispositivos')
						.doc(idRef)
						.set(device)
						.then(() => {
							this.props.enqueueSnackbar('Dispositivo deletado com sucesso', {
								variant: 'success',
								autoHideDuration: 2500
							});
							this.loadPage();
						});
				});
		}
	};

	// nextPage = () => {
	// 	const { maxPerPage, lastVisible } = this.state;
	// 	this.setState({
	// 		loading: true
	// 	});
	// 	firebase.firestore().collection('admin-dispositivos')
	// 		.orderBy('device_id_traccar')
	// 		.startAfter(lastVisible)
	// 		.limit(maxPerPage)
	// 		.get()
	// 		.then(documentSnapshots => {
	// 			if (documentSnapshots.docs.length > 0) {
	// 				const lastVisible =
	// 					documentSnapshots.docs[documentSnapshots.docs.length - 1];
	// 				this.setState({
	// 					previusStartAfter: [
	// 						...this.state.previusStartAfter,
	// 						documentSnapshots.docs[0]
	// 					],
	// 					lastVisible: lastVisible,
	// 					list: new Map(
	// 						documentSnapshots.docs.map(snap => [snap.id, snap.data()])
	// 					),
	// 					loading: false
	// 				});
	// 			}
	// 			this.setState({
	// 				loading: false
	// 			});
	// 		});
	// };

    // previusPage = () => {
    // 	let { previusStartAfter } = this.state;
    // 	const startAfter = previusStartAfter[previusStartAfter.length - 2];
    // 	if (startAfter) {
    // 		this.setState({
    // 			loading: true
    // 		});
    // 		previusStartAfter = previusStartAfter.slice(
    // 			0,
    // 			previusStartAfter.length - 2
    // 		);

	// 		firebase.firestore().collection('admin-dispositivos')
	// 			.orderBy('device_id_traccar')
	// 			.startAt(startAfter)
	// 			.limit(1)
	// 			.get()
	// 			.then(documentSnapshots => {
	// 				if (documentSnapshots.docs.length > 0) {
	// 					const lastVisible =
	// 						documentSnapshots.docs[documentSnapshots.docs.length - 1];

    // 					this.setState({
    // 						previusStartAfter: [
    // 							...this.state.previusStartAfter,
    // 							documentSnapshots.docs[0]
    // 						],
    // 						lastVisible: lastVisible,
    // 						list: new Map(
    // 							documentSnapshots.docs.map(snap => [snap.id, snap.data()])
    // 						)
    // 					});
    // 				}
    // 				this.setState({
    // 					loading: false
    // 				});
    // 			});
    // 		this.setState({
    // 			previusStartAfter,
    // 			loading: false
    // 		});
    // 	}
    // };

    render() {
        const { list, loading } = this.state;
        return (
            <React.Fragment>
                <Typography
                    style={{ fontWeight: 'bold' }}
                    variant="h5"
                    color="inherit"
                >
                    Dispositivos
                    <Button
                        variant="contained"
                        onClick={() => this.setState({ modalOpen: true })}
                        style={{ float: 'right' }}
                    >
                        Adicionar dispositivo
                    </Button>
                </Typography>
                <br />

                <Divider />

                <Paper style={rootStyles}>
                    <Table style={tableStyles}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome do dispositivo</TableCell>
                                <TableCell>Identificador</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableCell
                                        style={{ padding: '1em 2em' }}
                                        colSpan={5}
                                    >
                                        Carregando...
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading && [...list.keys()].length === 0 && (
                                <TableRow>
                                    <TableCell
                                        style={{ padding: '1em 2em' }}
                                        colSpan={5}
                                    >
                                        Não foi encontrado nenhum dispositivo
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading &&
                                [...list.keys()].map(keyDispositivo => {
                                    const dispositivo = list.get(
                                        keyDispositivo
                                    );
                                    return (
                                        <TableRow key={dispositivo.id}>
                                            <TableCell>
                                                {dispositivo.name}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    dispositivo.identificador_traccar
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        this.deleteDevice(
                                                            keyDispositivo
                                                        )
                                                    }
                                                >
                                                    <IconDelete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    {/* <Grid container>
						<Grid item xs={3} lg={3} />
						<Grid item xs={3} lg={3}>
							<Button
								variant="contained"
								style={{ float: 'right' }}
								onClick={this.previusPage}
							>
								Anterior
							</Button>
						</Grid>
						<Grid item xs={3} lg={3}>
							<Button variant="contained" onClick={this.nextPage}>
								Próximo
							</Button>
						</Grid>
						<Grid item xs={3} lg={3} />
					</Grid> */}
                </Paper>
                <SnackbarProvider maxSnack={2}>
                    <DispositivoModal
                        onClose={() => this.setState({ modalOpen: false })}
                        onCreate={this.loadPage}
                        open={this.state.modalOpen}
                    />
                </SnackbarProvider>
            </React.Fragment>
        );
    }
}

const ListarDispositivos = withSnackbar(_ListarDispositivos);

class ListarDispositivosPage extends Component {
    render() {
        return (
            <SnackbarProvider maxSnack={2}>
                <ListarDispositivos />
            </SnackbarProvider>
        );
    }
}

export default withSnackbar(ListarDispositivosPage);
