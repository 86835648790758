import React from 'react';
import { ChromePicker } from 'react-color';
import Button from '@material-ui/core/Button';

class ButtonColorPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: false,
            color: props.color
        };
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false });
    };

    onChangeColor = ({ hex }) => {
        this.props.onChange(hex);
        this.setState({
            color: hex
        });
    };

    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
            right: '2em'
        };
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px'
        };
        return (
            <div style={this.props.style}>
                <Button
                    variant="contained"
                    style={{
                        background: this.state.color,
                        padding: this.props.children ? '' : '1.31em 0em'
                    }}
                    onClick={this.handleClick}
                >
                    {this.props.children || ''}
                </Button>
                {this.state.displayColorPicker ? (
                    <div style={popover}>
                        <div style={cover} onClick={this.handleClose} />
                        <ChromePicker
                            color={this.state.color}
                            onChange={this.onChangeColor}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default ButtonColorPicker;
