import React, { useEffect, useState } from 'react';

import firebase from './configs/firebase';

import { unstable_batchedUpdates } from 'react-dom';

import CssBaseline from '@material-ui/core/CssBaseline';

import IconLocation from '@material-ui/icons/LocationOn';
import IconMap from '@material-ui/icons/Map';
// import IconDevices from '@material-ui/icons/DevicesOther';
import IconNotifications from '@material-ui/icons/NotificationsActive';
import MessageOutlined from '@material-ui/icons/MessageOutlined';
import { Route, withRouter, Switch } from 'react-router-dom';

import ListarDispositivos from './adminComponents/ListarDispositivos';
import MenuAppBar from './adminComponents/MenuAppBar';
import Rota from './adminComponents/Rota';
import IconOlaOnibus from './images/icone-ola-onibus-preto.svg';

import Login from './pages/Login';
import GerenciadorPontos from './pages/GerenciadorPontos';
import NovoPonto from './adminComponents/NovoPonto';
import ListarRotas from './pages/ListarRotas';
import VisaoGeralRotas from './pages/VisaoGeralRotas';
import ListarFeedBack from './pages/ListarFeedBack';
import GerenciarFeriado from './pages/GerenciarFeriado';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	'@global': {
		'html, body, #root': {
			height: '100%',
			width: '100%',
			margin: 0,
			padding: 0,
			display: 'flex',
			flexDirection: 'column'
		}
	}
});

const App = ({ history }) => {
	useStyles();

	const [user, setUser] = useState();
	const [userLoading, setUserLoading] = useState(true);

	const [userAdmin, setUserAdmin] = useState();
	const [userAdminLoading, setUserAdminLoading] = useState(true);

	useEffect(
		() =>
			firebase.auth().onAuthStateChanged(user => {
				unstable_batchedUpdates(() => {
					setUser(user);
					setUserLoading(false);
				});
			}),
		[]
	);

	useEffect(() => {
		if (!userLoading) {
			setUserAdminLoading(true);

			if (user) {
				firebase
					.firestore()
					.collection('admins')
					.doc(user.uid)
					.onSnapshot(snapshot => {
						unstable_batchedUpdates(() => {
							setUserAdmin(snapshot.get('isAdmin'));
							setUserAdminLoading(false);
						});
					});
			} else {
				setUserAdminLoading(false);
			}
		}
	}, [userLoading, user]);

	if (userLoading || userAdminLoading) return null;

	if (!user) return <Login />;

	if (!userAdmin)
		return (
			<div>
				Area Restrita
				<button onClick={() => firebase.auth().signOut()}>Sair</button>
			</div>
		);

	return (
		<>
			<CssBaseline />
			<MenuAppBar
				title="Olá Ônibus"
				onClickItemMenu={link => history.push(link)}
				items={[
					{
						name: (
							<img
								alt=""
								src={IconOlaOnibus}
								width={180}
								style={{ margin: '0.3em', color: 'white' }}
							/>
						),
						items: [
							{
								icon: <IconMap />,
								title: 'Rotas',
								link: '/Rotas'
							},

							{
								icon: <IconLocation />,
								title: 'Pontos',
								link: '/Pontos'
							},
							// {
							// 	icon: <IconDevices />,
							// 	title: 'Dispositivos',
							// 	link: `${match.url}/Dispositivos`
							// },
							{
								icon: <IconNotifications />,
								title: 'Feriados',
								link: '/GerenciarFeriado'
							},
							{
								icon: <MessageOutlined />,
								title: 'FeedBack',
								link: '/FeedBack'
							},
						]
					}
				]}
			/>

			<Switch>
				<Route path="/Rota/:uidRota?" component={Rota} />
				<Route exact path="/" component={ListarRotas} />
				<Route exact path="/Rotas" component={ListarRotas} />
				<Route exact path="/Dispositivos" component={ListarDispositivos} />

				<Route exact path="/FeedBack" component={ListarFeedBack} />
				<Route exact path="/Pontos" component={GerenciadorPontos} />
				<Route exact path="/NovoPonto" component={NovoPonto} />

				<Route exact path="/VisaoGeralRotas" component={VisaoGeralRotas} />
				<Route exact path="/GerenciarFeriado" component={GerenciarFeriado} />
			</Switch>
		</>
	);
};

export default withRouter(App);
