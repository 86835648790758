import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddTimeIcon from '../images/add-time.svg';
import {
    TextField,
    Box,
    IconButton,
} from '@material-ui/core';

const styles = theme => ({
    textField: {
        width: '100%'
    },
});

function TimePickers(props) {
    const {
        classes,
        onChange,
        title = 'horário',
        defaultValue,
        onEnter
    } = props;

    return (
        <Box display="flex" alignItems="center" >
            <Box>
                <TextField
                    variant="filled"
                    id="time"
                    label={title}
                    type="time"
                    defaultValue={defaultValue}
                    className={classes.textField}
                    fullWidth
                    InputLabelProps={{
                        shrink: true
                    }}
                    onKeyPress={ev => {
                        if (ev.key === 'Enter') {
                            onEnter();
                            ev.preventDefault();
                        }
                    }}
                    onChange={onChange}
                />
            </Box>
                <IconButton
                    variant="contained"
                    color="primary"
                    onClick={onEnter}
                >
                        <img src={AddTimeIcon} width="30" alt="" />
                </IconButton>
        </Box>
    );
}

TimePickers.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TimePickers);
