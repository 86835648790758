import React, { Component } from 'react';
import {
	Divider,
	Grid,
	TextField,
	Button,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	IconButton,
	Icon,
	Paper
} from '@material-ui/core';
import { AddAlarm } from '@material-ui/icons';
import dayjs from 'dayjs';
import * as firebase from 'firebase/app';

import Pagina from '../adminComponents/Pagina';

class GerenciarFeriado extends Component {
	state = {
		nome: '',
		diaMes: dayjs().format('DD-MM-YYYY'),
		lista: new Map(),
		loading: true
	};

	componentDidMount = () => {
		this.getUidUser = firebase.auth().onAuthStateChanged(user => {
			this.user_uid = user.uid;
			this.carregarRegistros();
		});
	};

	componentWillUnmount() {
		if (typeof this.listenerFeriados === 'function') this.listenerFeriados();
		if (typeof this.getUidUser === 'function') this.getUidUser();
	}

	carregarRegistros = () => {
		this.listenerFeriados = firebase
			.firestore()
			.collection('feriados')
			.orderBy('milliseconds')
			.onSnapshot(snaps => {
				this.setState({
					lista: new Map(snaps.docs.map(snap => [snap.id, snap.data()])),
					loading: false
				});
			});
	};

	adicionarFeriado = () => {
		let { dia, mes, nome, ano, diaMes } = this.state;

		if (diaMes && nome) {
			let feriadoRef = firebase
				.firestore()
				.collection('feriados')
				.doc();

			let date = dayjs(diaMes);

			dia = date.format('DD');
			mes = date.format('MM');
			ano = date.format('YYYY');

			firebase
				.firestore()
				.collection('feriados')
				.doc(feriadoRef.id)
				.set({
					id: feriadoRef.id,
					nome,
					dia,
					mes,
					ano,
					milliseconds: date.valueOf(),
					usuario: this.user_uid
				});

			this.setState({
				nome: '',
				expiravel: false,
				expires: new Date()
			});
		} else {
			alert('Preencha todos os campos necessarios');
		}
	};

	deletarFeriado = id =>
		firebase
			.firestore()
			.collection('feriados')
			.doc(id)
			.delete();

	onChange = (nomeCampo, e) => {
		this.setState({
			[nomeCampo]: e.target.value
		});
	};

	render() {
		const { nome, lista, loading } = this.state;

		return (
			<Pagina>
				<h2>Feriados</h2>
				<Divider />

				<Grid justify="center" container>
					<Grid style={{ margin: '0 10px' }} item>
						<TextField
							variant="filled"
							id="standard-full-width"
							label="Nome"
							placeholder="Nome do feriado"
							fullWidth
							value={nome}
							margin="normal"
							onChange={e => this.setState({ nome: e.target.value })}
							InputLabelProps={{
								shrink: true
							}}
						/>
					</Grid>

					<Grid style={{ margin: '0 10px' }} item>
						<TextField
							variant="filled"
							id="date"
							label="Data"
							type="date"
							defaultValue=" "
							margin="normal"
							fullWidth
							onChange={e => this.setState({ diaMes: e.target.value })}
							InputLabelProps={{
								shrink: true
							}}
						/>
					</Grid>

					<Grid item>
						<Button
							style={{ marginTop: '26px', marginLeft: '10px' }}
							variant="contained"
							onClick={this.adicionarFeriado}
						>
							<AddAlarm />
						</Button>
					</Grid>
				</Grid>
				<br />
				<br />
				<Grid justify="center" style={{ margin: '10px 0' }} container>
					<Paper style={{ minWidth: 700 }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Nome do feriado</TableCell>
									<TableCell> Data </TableCell>
									<TableCell> Ações </TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{lista.size === 0 && (
									<TableRow>
										<TableCell style={{ padding: '1em 2em' }} colSpan={4}>
											{loading
												? 'Carregando...'
												: 'Nenhum registro encontrado.'}
										</TableCell>
									</TableRow>
								)}

								{lista.size > 0 &&
									[...lista.values()].map((feriado, i) => {
										return (
											<TableRow key={i}>
												<TableCell
													style={{
														color:
															dayjs(feriado.milliseconds).diff(dayjs()) < 0
																? 'red'
																: 'black'
													}}
												>
													{feriado.nome}{' '}
												</TableCell>
												<TableCell>
													{feriado.dia}/{feriado.mes}/{feriado.ano}
												</TableCell>

												<TableCell>
													<IconButton
														onClick={() => this.deletarFeriado(feriado.id)}
													>
														<Icon>delete_icon</Icon>
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</Paper>
				</Grid>
			</Pagina>
		);
	}
}

export default GerenciarFeriado;
