import React, { Component } from 'react';
import firebase from 'firebase/app';

import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import StylesMapBox from '../configs/style_mapbox.json';

import 'mapbox-gl/dist/mapbox-gl.css';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import pontoIcon from '../images/icone-ponto-onibus.svg';
import pontoIconSelect from '../images/pontoIconSelect.svg';
import CardPonto from '../adminComponents/CardPonto';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ClearIcon from '@material-ui/icons/Clear';
import { Typography, Divider, Box } from '@material-ui/core';

import Pagina from '../adminComponents/Pagina';

const MapBox = ReactMapboxGl({
	accessToken:
		'pk.eyJ1IjoiY3Jvc3NrcGl4ZWwiLCJhIjoiY2pjaHR2eTZmMDlueTMzbnNqNXdiczFvNyJ9.8BD7dd_uSkBW_gynYydR_A',
	refreshExpiredTiles: false
});

class GerenciadorPontos extends Component {
	state = {
		localizacao: null,
		pontos: new Map(),
		mapaCentro: [-52.372141, -24.04541],
		zoom: [14],
		selected: '',
		selectedImage: '',
		posicaoPontoParaUpdate: {}
	};

	componentDidMount() {
		this.pontos = firebase
			.firestore()
			.collection('pontos')
			.onSnapshot(querySnapshot => {
				this.setState({
					pontos: new Map(
						querySnapshot.docs.map(snapshot => [
							snapshot.id,
							{
								...snapshot.data(),
								id: snapshot.id,
								visibility: true
							}
						])
					)
				});
			});

		this.pontoImg = new Image(50, 50);
		this.pontoImg.src = pontoIcon;

		this.pontoImgSelect = new Image(50, 50);
		this.pontoImgSelect.src = pontoIconSelect;
	}

	componentWillUnmount() {
		if (typeof this.pontos === 'function') this.pontos();
		if (typeof this.imagens === 'function') this.imagens();
	}

	selectPonto = _ponto => {
		let { pontos, selected } = this.state;
		let ponto;
		if (selected) {
			ponto = pontos.get(selected);
			ponto.visibility = true;
			pontos.set(ponto.id, ponto);
		}
		ponto = pontos.get(_ponto.id);
		ponto.visibility = false;
		pontos.set(ponto.id, ponto);

		this.setState({
			selectedImage: '',
			selected: ponto.id,
			zoom: [20],
			mapaCentro: [ponto.longitude, ponto.latitude],
			posicaoPontoParaUpdate: [ponto.longitude, ponto.latitude],
			velocidade_via: ponto.velocidade_via
		});

		this.imagens = firebase
			.firestore()
			.collection('imagensPontos')
			.where('ponto', '==', ponto.id)
			.onSnapshot(querySnapshot =>
				this.setState({
					selectedImage: querySnapshot.docs.map(snapshot => snapshot.data())
				})
			);
	};

	atualizarPonto = () => {
		const { selected, posicaoPontoParaUpdate, velocidade_via } = this.state;

		const db = firebase.firestore();
		db.collection('pontos')
			.doc(selected)
			.get()
			.then(r => {
				let ponto = r.data();
				ponto.longitude = posicaoPontoParaUpdate[0];
				ponto.latitude = posicaoPontoParaUpdate[1];
				ponto.velocidade_via = parseInt(velocidade_via ? velocidade_via : 30);
				db.collection('pontos')
					.doc(selected)
					.update(ponto)
					.then(() => {
						alert('Ponto de embarque atualizado com sucesso');
						this.setState({
							selected: '',
							selectedImage: '',
							zoom: [15]
						});
					});
			});
	};

	deletarPonto = async () => {

		if(window.confirm(`Deseja realmente deletar este ponto ? Todas linhas que possuem este ponto serão desvinculados`) === false) {
			return;
		}

		const {
			pontos,
			selected,
		} = this.state;

		let ponto = pontos.get(selected);

		const db = firebase.firestore();

		const { docs } = await db.collection('linhas').get();

		for(let doc of docs) {
			let linha = doc.data();
			if(linha.pontos.includes(ponto.id)) {
				linha.pontos = linha.pontos.filter(pontoId => pontoId !== ponto.id);
				await db.collection(`linhas`).doc(linha.id).set(linha);
			}
		}

		this.setState({
			selected: '',
			selectedImage: '',
			zoom: [15],
		})

		await db
			.collection('pontos')
			.doc(ponto.id).delete();
	}

	render() {
		const {
			pontos,
			selected,
			selectedImage,
			posicaoPontoParaUpdate,
			velocidade_via
		} = this.state;
		return (
			<Pagina p={0} display="flex" flexDirection="column">
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flexWrap="wrap"
					pt={2}
					px={2}
				>
					<Box clone pb={2} flexGrow={1}>
						<Typography variant="h5">
							Gerenciador de Pontos de embarque
						</Typography>
					</Box>
					<Box clone flex="none" mx="auto" mb={2}>
						<Button
							variant="contained"
							onClick={() => this.props.history.replace('/NovoPonto')}
						>
							Novo ponto
						</Button>
					</Box>
				</Box>

				<Divider />

				<Box clone flex={1}>
					<Grid container>
						<Grid item xs={7}>
							<MapBox
								style={StylesMapBox}
								containerStyle={{
									height: '100%',
									width: '100%'
								}}
								center={this.state.mapaCentro}
								onZoomEnd={map => {
									if (this.state.zoom[0] !== map.getZoom() && !this.moving) {
										this.setState({ zoom: [map.getZoom()] });
									}
								}}
								onDragEnd={map => {
									const novoCentro = map.getCenter();

									if (
										this.state.mapaCentro[0] !== novoCentro.lng ||
										(this.state.mapaCentro[1] !== novoCentro.lat &&
											!this.moving)
									) {
										this.setState({
											mapaCentro: [novoCentro.lng, novoCentro.lat]
										});
									}
								}}
								onMove={map => {
									this.moving = map.isMoving();
								}}
								onMoveEnd={map => {
									this.moving = map.isMoving();
								}}
								zoom={this.state.zoom}
							>
								{pontos.size > 0 ? (
									<React.Fragment>
										<Layer
											id="PonotosLayer"
											type="symbol"
											layout={{
												'icon-image': 'PontoIcon',
												'icon-allow-overlap': true,
												'icon-size': [
													'interpolate',
													['exponential', 1],
													['zoom'],
													0,
													1,
													10,
													0.5,
													21,
													1
												],
												'symbol-avoid-edges': true
											}}
											images={['PontoIcon', this.pontoImg]}
											icon-anchor="center"
										>
											{[...pontos.values()].map(ponto => {
												return (
													<Feature
														key={ponto.id}
														onClick={() => this.selectPonto(ponto)}
														coordinates={
															ponto.visibility
																? [ponto.longitude, ponto.latitude]
																: [0, 0]
														}
													/>
												);
											})}
										</Layer>

										<Layer
											id="PonotoSelectedLayer"
											type="symbol"
											layout={{
												'icon-image': 'PontoIconSelect',
												'icon-allow-overlap': true,
												'icon-size': [
													'interpolate',
													['exponential', 1],
													['zoom'],
													0,
													1,
													9,
													0.5,
													19,
													1
												],
												'symbol-avoid-edges': true,
												visibility: this.state.selected ? 'visible' : 'none'
											}}
											images={['PontoIconSelect', this.pontoImgSelect]}
											icon-anchor="center"
										>
											<Feature
												draggable={true}
												onDragEnd={mapEvent => {
													const { lngLat } = mapEvent;
													const { lng, lat } = lngLat;
													this.setState({
														posicaoPontoParaUpdate: [lng, lat]
													});
												}}
												coordinates={
													selected && pontos.get(selected)
														? [
																pontos.get(selected).longitude,
																pontos.get(selected).latitude
														  ]
														: [0, 0]
												}
											/>
										</Layer>
									</React.Fragment>
								) : null}
							</MapBox>
						</Grid>

						<Grid item xs={5}>
							{!selected && (
								<Grid
									container
									style={{ height: '100%' }}
									alignContent="center"
									alignItems="center"
									direction="column"
									justify="center"
								>
									<Grid item xl={5} />
									<Grid item xl={2}>
										Selecione um ponto para editar.
									</Grid>
									<Grid item xl={5} />
								</Grid>
							)}

							{selected && (
								<CardPonto
									footer={
										<React.Fragment>
											<Button
												// onClick={onClickButtonLeft}
												size="small"
												variant="contained"
												onClick={() => {
													let { selected, pontos } = this.state;
													let ponto = pontos.get(selected);
													ponto.visibility = true;
													pontos.set(ponto.id, ponto);
													this.setState({
														selected: '',
														pontos,
														zoom: [14],
														selectedImage: ''
													});
												}}
											>
												Voltar <ClearIcon />
											</Button>
											<Button
												onClick={this.deletarPonto}
												size="small"
												variant="contained"
												color="secondary"
											>
												Deletar <DeleteIcon />
											</Button>
											<Button
												onClick={this.atualizarPonto}
												size="small"
												variant="contained"
												color="primary"
											>
												Atualizar <DoneAllIcon />
											</Button>
										</React.Fragment>
									}
									title="Ponto de embarque"
									// loading={selectedImage[0] ? false : true}
									loading={false}
									imageSrc={
										selectedImage[0] ? selectedImage[0]['800x600'] : false
									}
								>
									<div>ID: {pontos.get(selected).id}</div>
									<div>Longitude: {pontos.get(selected).longitude}</div>
									<div>Latituide: {pontos.get(selected).latitude}</div>
									<div>Nova Longitude: {posicaoPontoParaUpdate[0]}</div>
									<div>Nova Latitude: {posicaoPontoParaUpdate[1]}</div>
									<div>
										Velocidade da via:
										<input
											value={velocidade_via}
											onChange={e =>
												this.setState({
													velocidade_via: e.target.value
												})
											}
										/>
									</div>
								</CardPonto>
							)}
						</Grid>
					</Grid>
				</Box>
			</Pagina>
		);
	}
}

export default GerenciadorPontos;
