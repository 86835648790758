import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%'
    }
});

function DateTimePicker({
    classes,
    value,
    label = 'Selecione uma data',
    onChange,
    refToState
}) {
    // '2017-05-24T10:30',
    return (
        <form className={classes.container} noValidate>
            <TextField
                id="datetime-local"
                onChange={e => onChange(e, refToState)}
                label={label}
                type="datetime-local"
                className={classes.textField}
                variant="filled"
                value={value}
                InputLabelProps={{
                    shrink: true
                }}
            />
        </form>
    );
}

DateTimePicker.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DateTimePicker);
