import React from 'react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';

const _SelectDevice = ({
    onChange,
    items,
    refToState,
    disabled,
    value = false
}) => {
    return (
        <FormControl>
            <InputLabel htmlFor="device">Dispositivo</InputLabel>
            <Select
                disabled={disabled}
                value={value ? value : items[0].value}
                input={
                    <Input
                        onChange={e => onChange(e, refToState)}
                        id="device"
                    />
                }
            >
                <MenuItem value="">
                    <em>Selecione um dispositivo</em>
                </MenuItem>
                {items.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                        {item.desc}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
export default withStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}))(_SelectDevice);
