import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import IconOlaOnibus from '../images/icone-ola-onibus-preto.svg';
import firebase from 'firebase/app';

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing(3)
	},
	paper: {
		padding: theme.spacing(2),
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	divider: {
		width: '100%',
		margin: theme.spacing(2)
	}
});

const Login = ({ classes }) => {
	const [failedLogin, setFailedLogin] = useState(false);

	return (
		<main className={classes.main}>
			<CssBaseline />
			<Paper className={classes.paper}>
				{/* <Avatar className={classes.avatar}>
							<LockIcon />
						</Avatar> */}

				{/* <Typography component="h1" variant="h5">
							Olá Ônibus
						</Typography> */}
				<img width="250" alt="" src={IconOlaOnibus} />
				<br />
				<label>Administrativo</label>
				<form
					onSubmit={e => {
						e.preventDefault();
						const { email, password } = e.target;

						firebase
							.auth()
							.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
							.then(() => {
								firebase
									.auth()
									.signInWithEmailAndPassword(email.value, password.value)
									.then(() => setFailedLogin(false))
									.catch(() => setFailedLogin(true));
							});
					}}
					className={classes.form}
				>
					<FormControl margin="normal" error={failedLogin} required fullWidth>
						<InputLabel htmlFor="email">Email</InputLabel>
						<Input id="email" name="email" autoComplete="email" autoFocus />
					</FormControl>
					<FormControl margin="normal" error={failedLogin} required fullWidth>
						<InputLabel htmlFor="password">Senha</InputLabel>
						<Input
							name="password"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
					</FormControl>
					{failedLogin && (
						<Typography align="center" color="error">
							Usuário ou senha incorretos
						</Typography>
					)}
					{/* <Button
								type="button"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								onClick={this.loginWithPopup}
							>
								Entrar com Google
							</Button> */}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						Entrar
					</Button>
				</form>
				<Divider className={classes.divider} />
				<Button
					variant="outlined"
					onClick={() => {
						firebase
							.auth()
							.signInWithRedirect(new firebase.auth.GoogleAuthProvider());
					}}
				>
					Entrar Com Google
				</Button>
			</Paper>
		</main>
	);
};

Login.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Login);
