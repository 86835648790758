import React, { Component } from 'react';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
// import LayerPontos from '../components/LayerPontos';
import { Typography, Button } from '@material-ui/core';
import pontoIconSelect from '../images/pontoIconSelect.svg';
import firebase from 'firebase/app';
import { DropzoneArea } from 'material-ui-dropzone';
import StylesMapBox from '../configs/style_mapbox.json'
import dayjs from 'dayjs';
import 'firebase/database';
import 'firebase/storage';

let initialCenter = [52.37663, -24.038186];

let pontoImgSelect = new Image(50, 50);
pontoImgSelect.src = pontoIconSelect;


const MapBox = ReactMapboxGl({
	accessToken:
		'pk.eyJ1IjoiY3Jvc3NrcGl4ZWwiLCJhIjoiY2pjaHR2eTZmMDlueTMzbnNqNXdiczFvNyJ9.8BD7dd_uSkBW_gynYydR_A',
	refreshExpiredTiles: false
});

export default class NovoPonto extends Component {
	state = {
		ponto: {
			latitude: initialCenter[0],
			longitude: initialCenter[1]
		},
		fileImage: undefined
	};

	componentDidMount() {
		this.getUidUser = firebase.auth().onAuthStateChanged(user => {
			this.user_uid = user.uid;
		});
	}

	salvar = () => {
		if (!this.state.ponto.longitude || !this.state.ponto.latitude) {
			alert('Clique no mapa e informe a localização.');
			return;
		}

		const db = firebase.firestore();

		const refPonto = db.collection('pontos').doc();
		const refImagemPonto = db.collection('imagensPontos').doc();

		const objPonto = {
			id: refPonto.id,
			date: dayjs().unix(),
			velocidade_via: 40,
			bairro: '',
			...this.state.ponto
		};

		if(!this.state.fileImage) {
			refPonto.set(objPonto);
		}

		if(this.state.fileImage) {
			const refStorage = firebase
				.storage()
				.ref()
				.child('imagensPontos/original/' + refImagemPonto.id);
			refStorage.put(this.state.fileImage).then(resultUpload => {
				objPonto.path = refStorage.fullPath;
				refPonto.set(objPonto).then(() => {
					refImagemPonto.set({
						id: refImagemPonto.id,
						path: refStorage.fullPath,
						ponto: objPonto.id,
						versao: 1
					});
				});
			});
		}

		this.props.history.replace('/Pontos');
		
	};

	render() {
		const { ponto, fileImage } = this.state;

		return (
			<React.Fragment>
				<Typography
					style={{ fontWeight: 'bold' }}
					variant='h5'
					color='inherit'>
					Novo ponto
				</Typography>
				<MapBox
					style={StylesMapBox}
					containerStyle={{
						height: '60vh',
						width: '100%'
					}}
					maxBounds={[
						[-52.500299054383206, -24.27988414894527],
						[-52.26913534007542, -23.822796478590945]
					]}
					onStyleLoad={map => map.setCenter(initialCenter)}
					movingMethod='jumpTo'
					onClick={(map, evt) => {
						const { lat, lng } = evt.lngLat;
						this.setState({
							ponto: {
								latitude: lat,
								longitude: lng
							}
						});
					}}>
					<Layer
						id='PontoNovoLayer'
						type='symbol'
						layout={{
							'icon-image': 'NovoPontoIconSelect',
							'icon-allow-overlap': true,
							'icon-size': [
								'interpolate',
								['exponential', 1],
								['zoom'],
								0,
								1,
								9,
								0.5,
								19,
								1
							],
							'symbol-avoid-edges': true
							// visibility: this.state.selected ? 'visible' : 'none'
						}}
						images={['NovoPontoIconSelect', pontoImgSelect]}
						icon-anchor='center'>
						<Feature
							draggable={true}
							onDragEnd={mapEvent => {
								const { lng, lat } = mapEvent.lngLat;
								this.setState({
									ponto: {
										latitude: lat,
										longitude: lng
									}
								});
							}}
							coordinates={[ponto.longitude, ponto.latitude]}
						/>
					</Layer>
				</MapBox>

				{fileImage ? (
					<Button
						color='warning'
						onClick={() => {
							this.setState({ fileImage: null });
						}}>
						Escolher outra foto
					</Button>
				) : (
					<DropzoneArea
						onChange={files => {
							this.setState({
								fileImage: files[0]
							});
						}}
					/>
				)}
				<Button
					size='small'
					variant='contained'
					color='primary'
					style={{ width: '100%', marginTop: '20px' }}
					onClick={this.salvar}>
					Salvar
				</Button>
			</React.Fragment>
		);
	}
}
