import React from 'react';
import { Layer, Feature } from 'react-mapbox-gl';

const LayerCircle = ({
    id,
    features,
    onClick = () => null,
    color = 'red',
    onDragEnd = false,
    radius = 8
}) => {
    return (
        id && (
            <Layer
                type="circle"
                id={id}
                paint={{
                    'circle-radius': radius,
                    'circle-color': color
                }}
            >
                {features
                    .filter(_ => _)
                    .map((obj, i) => {
                        const { longitude, latitude } = obj;
                        return (
                            <Feature
                                onClick={() => onClick(obj)}
                                key={i}
                                coordinates={[longitude, latitude]}
                                draggable={!!onDragEnd}
                                onDragEnd={mapEvent => onDragEnd(mapEvent, obj)}
                            />
                        );
                    })}
            </Layer>
        )
    );
};

export default LayerCircle;
