import React, { Component } from 'react';
import { GeoJSONLayer, Source, Layer } from 'react-mapbox-gl';

const defaultProps = {
    color: '#F49F05',
    rota: null,
    width: 4,
    onClick: id => console.log('LayerLinha id=' + id + ' foi clicado'),
    showIndicators: false
};

class LinhaLayer extends Component {
    render() {
        const {
            rota,
            color,
            onClick,
            width,
            showIndicators,
            ...props
        } = this.props;

        try {
            return rota ? (
                <React.Fragment>
                    <GeoJSONLayer
                        lineOnClick={onClick}
                        data={{
                            type: 'Feature',
                            properties: {},
                            geometry: rota
                        }}
                        lineLayout={{
                            'line-join': 'round',
                            'line-cap': 'round'
                        }}
                        linePaint={{
                            'line-color': color ? color : defaultProps.color,
                            'line-width': width
                        }}
                        {...props}
                    />
                    {showIndicators && (
                        <React.Fragment>
                            <Source
                                id="geo"
                                geoJsonSource={{
                                    type: 'geojson',
                                    data: rota ? rota : []
                                }}
                            />
                            <Layer
                                id="flexinhas"
                                type="symbol"
                                sourceId="geo"
                                layout={{
                                    'symbol-placement': 'line',
                                    'text-field': '>',
                                    'text-size': [
                                        'interpolate',
                                        ['linear'],
                                        ['zoom'],
                                        24,
                                        28,
                                        44,
                                        120
                                    ],
                                    'symbol-spacing': [
                                        'interpolate',
                                        ['linear'],
                                        ['zoom'],
                                        12,
                                        30,
                                        22,
                                        160
                                    ],
                                    'text-keep-upright': false
                                }}
                                paint={{
                                    'text-color': color,
                                    'text-halo-color': 'hsl(55, 11%, 96%)',
                                    'text-halo-width': 2
                                }}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            ) : null;
        } catch (err) {
            return null;
        }
    }
}
LinhaLayer.defaultProps = defaultProps;

export default LinhaLayer;
