import React, { Component } from 'react';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import dayjs from 'dayjs';
import LayerPontos from '../components/LayerPontos';
import LayerCircle from '../components/LayerCircle';
import LinhaLayer from '../components/LayerLinha';
import {
    Divider,
    Button,
    TextField,
    Box,
    Tabs,
    Tab,
    IconButton,
    Typography
} from '@material-ui/core';
import Snack from './Snack';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import List from '@material-ui/core/List';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import SelectItem from './SelectItems';
import DateTimePicker from './DateTimePicker';
import HorariosOnibus from './HorariosOnibus';
import * as turf from '@turf/turf';
import length from '@turf/length';
import ButtonColorPicker from './ButtonColorPicker';
import IconePontoOnibus from '../images/pontoIconSelect.svg';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import Add from '@material-ui/icons/Add';
import StylesMapBox from '../configs/style_mapbox.json';

import * as firebase from 'firebase/app';
import _ from 'lodash';

import {
    Place,
    Delete,
    Directions,
    NotListedLocation,
    DepartureBoard,
    Close,
    CallMerge,
} from '@material-ui/icons';

const MapBox = ReactMapboxGl({
    accessToken:
        'pk.eyJ1IjoiY3Jvc3NrcGl4ZWwiLCJhIjoiY2pjaHR2eTZmMDlueTMzbnNqNXdiczFvNyJ9.8BD7dd_uSkBW_gynYydR_A',
    refreshExpiredTiles: false,
    minZoom: 13,
    maxZoom: 20
});
let initialCenter = [-52.37663, -24.038186];

//fgTuaJozMJcRR1mo7TJP
const TERMINAL = [-52.37859606742859, -24.04493799300011];

const initialState = {
    mapaCentro: TERMINAL,
    restore: undefined,
    loading: false,
    deviceId: null,
    devices: new Map(),
    startDate: dayjs().format('YYYY-MM-DDTHH:mm'),
    endDate: dayjs().format('YYYY-MM-DDTHH:mm'),
    loadingConsulta: false,

    longitude: initialCenter[0],
    latitude: initialCenter[1],
    zoom: [12],
    terminais: {
        loading: false,
        dados: new Map([
            [
                'centro',
                {
                    id: 'centro',
                    longitude: -52.37859606742859,
                    latitude: -24.04493799300011
                }
            ]
        ])
    },

    pontosGps: new Map(),
    pontosGpsSelecionados: new Map(),

    hh: '12',
    mm: '00',

    // # Pontos de embarque, em pontosRota há a sequência ordenada dos pontos de embarque da linha.
    pontos: new Map(),
    pontosRota: [],
    pontoRotaSelecionado: undefined,

    idxSetorSelecionado: 0,
    setores: [],
    horarioExemplo: '12:00',

    nomeRota: '',
    cor: '#000',

    utilitario: [], // será informado o tipo do utilitario utilizado, e passara por uma verificação de evento de acordo com o tipo selecionado
    travado: false,
    marcadorSelecionado: undefined,

    alert: '',
    tab: 0,
    snackbar: undefined,
    salvando: false
};

class CriarRota extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            ...initialState,
            setores: [
                this.criarObjetoSetor(`S1`, [], 0),
            ]
         };
    }

    componentDidMount() {

        this.getUidUser = firebase.auth().onAuthStateChanged(user => {
            this.user_uid = user.uid;
        });

        const { uidRota } = this.props.match.params;
        if (uidRota) {
            this.setState({
                loading: true
            });
        }
        this.carregarDispositivos();
        this.carregarPontosEmbarque().then(() => {
            if (!uidRota) {
                return;
            }
            const linhasCollection = firebase.firestore().collection('linhas');
            linhasCollection
                .doc(uidRota)
                .get()
                .then(snap => {
                    let { pontosGpsSelecionados, pontosRota, pontos } = this.state;
                    const linha = snap.data();
                    linha.pontos.forEach(pontoId => {
                        let ponto = pontos.get(pontoId);
                        pontosRota.push(pontoId, ponto);
                    });

                    let { coordinates } = JSON.parse(linha.rota);

                    coordinates.forEach((pos, i) =>
                        pontosGpsSelecionados.set(i, {
                            id: i,
                            longitude: pos[0],
                            latitude: pos[1]
                        })
                    );

                    pontosRota = linha.pontos.map(pontoId =>
                        pontos.get(pontoId)
                    );

                    const objectToMap = (horarios) => new Map(
                        Object.keys(horarios).map(hora => [
                            hora,
                            horarios[hora]
                        ])
                    ) ;

                    // if(linha.setores == null || linha.setores == 0) {
                    //     this.criarNovoSetor();
                    // }

                    this.setState({
                        pontosGpsSelecionados,
                        pontosRota,
                        nomeRota: linha.nome,
                        cor: linha.cor,
                        loading: false,
                        // setores: linha.setores.map(setor => {
                        //     setor.horarios = objectToMap(setor.horarios);
                        //     return setor;
                        // }),
                        setores: linha.setores.map(setor => {
                            setor.horarios = objectToMap(setor.horarios);
                            setor.pontos = pontosRota.map(pr => {
                                let idx = setor.pontos.findIndex(pp => pp.id === pr.id );
                                if(idx === -1) {
                                    return null;
                                } else {
                                    return setor.pontos[idx];
                                }
                            } ).filter(_ => _)
                            return setor;
                        })
                    });

                });
        });
    }

    carregarDispositivos = () => {
        this.dispositivos = firebase
            .firestore()
            .collection('locTestDispositivos')
            .onSnapshot(deviceSnapshots => {
                this.setState({
                    devices: new Map(
                        deviceSnapshots.docs.map(snap => {
                            const device = snap.data();
                            return [device.id, device];
                        })
                    ),
                    deviceId:
                        this.state.devices.size === 0
                            ? deviceSnapshots.empty
                                ? undefined
                                : deviceSnapshots.docs[0].data().id
                            : this.state.deviceId
                });
            });
    };

    carregarPontosEmbarque = () => {
        return new Promise((resolve, reject) => {
            this.embarque = firebase
                .firestore()
                .collection('pontos')
                .onSnapshot(pontosSnapshots => {
                    this.setState({
                        pontos: new Map(
                            pontosSnapshots.docs.map(snap => {
                                let ponto = snap.data();
                                ponto.selected = false;
                                return [ponto.id, ponto];
                            })
                        )
                    });
                    resolve(true);
                });
        });
    };

    componentWillUnmount() {

        this.setState({
            pontosGpsSelecionados: new Map(),
            setores: [
                this.criarObjetoSetor(`S1`, [], 0),
            ],
            idxSetorSelecionado: 0,
            pontosRota: [],
        })

        new AbortController().abort();
        if (typeof this.pontos === 'function') {
            this.embarque();
        }
        if (typeof this.getUidUser === 'function') {
            this.getUidUser();
        }
        if (typeof this.dispositivos === 'function') {
            this.dispositivos();
        }
        if (typeof this.geolocation === 'function') {
            this.geolocation();
        }
        if (typeof this.getUidUser === 'function') {
            this.getUidUser();
        }
        if (typeof this.consultaDevicesFunction === 'function') {
            this.consultaDevicesFunction();
        }
    }

    onClickMarker = ponto => {
        this.setState(state => {
            let { pontosGps, pontosGpsSelecionados } = state;

            pontosGpsSelecionados.set(ponto.id, ponto);
            pontosGps.delete(ponto.id);

            return {
                pontosGps,
                pontosGpsSelecionados
            };
        });
    };

    setLatLng = ({ latitude, longitude }) =>
        this.setState({ latitude, longitude });

    changeDataInicial = date =>
        this.setState({
            startDate: date
        });
    changeDataFinal = date =>
        this.setState({
            endDate: date
        });
    onChange = (event, chave) => {
        this.setState({
            [chave]: event.target.value
        });
    };

    consultarRotas = () => {
        this.setState({
            loadingConsulta: true
        });
        let { startDate, endDate, deviceId } = this.state;

        let _startDate = dayjs(startDate).valueOf();
        let _endDate = dayjs(endDate).valueOf();

        firebase
            .firestore()
            .collection('locTest')
            .where('dispositivo', '==', deviceId)
            .orderBy('horario', 'asc')
            .startAt(_startDate)
            .endAt(_endDate)
            .get()
            .then(snaps => {
                if (snaps.docs.length === 0) {
                    this.setState({
                        loadingConsulta: false
                    });
                    this.snack({
                        message: 'Não foi encontrado nenhuma rota neste invervalo de datas',
                        variant: 'warning'
                    });
                    return;
                }

                this.setState({
                    pontosGps: new Map(
                        snaps.docs.map(pos => [
                            pos.id,
                            {
                                ...pos.data(),
                                id: pos.id
                            }
                        ])
                    ),
                    pontosGpsSelecionados: new Map(),
                    horarios: [],
                    pontos: new Map(),
                    pontosRota: [],
                    loadingConsulta: false,
                });
            });
    };

    snack = params => {
        this.setState({
            snackbar: { ...params }
        });
        setTimeout(() => {
            this.setState({
                snackbar: undefined
            });
        }, 3500);
    };

    onClickMarkerPontobus = ponto => {
        if (this.state.utilitario.includes('ADD_POINT_GPS')) {
            return;
        }
        let { pontosRota } = this.state;

        pontosRota.push(ponto);

        this.setState({
            pontosRota,
            latitude: ponto.latitude,
            longitude: ponto.longitude
        });
    };

    salvar = async () => {

        this.setState({
            salvando: true
        })

        const { uidRota } = this.props.match.params;

        let linhaRef = firebase
            .firestore()
            .collection('linhas')
            .doc();
        if (uidRota) {
            linhaRef = firebase
                .firestore()
                .collection('linhas')
                .doc(uidRota);
        }

        const {
            nomeRota,
            cor,
            pontosRota,
            pontosGpsSelecionados,
            setores
        } = this.state;

        // let horarios_dias = [...horarios.keys()].reduce((prev, current) => {
        //     prev[current] = horarios.get(current);
        //     return prev;
        // }, {});

        let jsonlinha = turf.lineString(
            [...pontosGpsSelecionados.values()].map(
                ({ longitude, latitude }) => [longitude, latitude]
            )
        );

        let rota = JSON.stringify(jsonlinha.geometry);

        // bug so está gravando a linha cortada devido o ultimo ponto ? por algum motivo muito estraanho
        const obj = {
            id: linhaRef.id,
            // horarios: horarios_dias,
            setores: setores.map(setor => {
                return {
                    ...setor,
                    horarios:Object.fromEntries(setor.horarios)
                }
            }),
            pontos: pontosRota.map(ponto => ponto.id),
            distancia: Number(
                length(jsonlinha, { units: 'kilometers' })
            ).toFixed(2),
            rota: rota,
            terminal: 'centro',
            nome: nomeRota,
            data_cadastro: firebase.firestore.FieldValue.serverTimestamp(),
            usuario: this.user_uid,
            cor: cor,
            excluido: false,
            ativo: true,
            versao: dayjs().unix()
        }
        
        await linhaRef.set(obj);

        this.setState({
            salvando: false,
        })

        this.snack({
            message: `Rota ${uidRota ? 'atualizada' : 'adicionada'} com sucesso`,
            position: 'right',
            variant: 'success'
        });
        const { history } = this.props;
        history.replace('/Rotas');
    };

    mostrarMensagemUtilitario = () => {
        const { utilitario } = this.state;

        let html = '';
        if (utilitario.includes('ADD_POINT_GPS')) {
            html += 'De um clique no mapa para adicionar o marcador ';
        }
        if (utilitario.includes('MERGE_MARCADOR')) {
            html += 'Clique no outro marcador (não selecionado) para unir ';
        }
        if (utilitario.includes('ON_CLICK_INTERVAL_POINTS_CREATE_POINT')) {
            html += "Clique em no meio da linha para gerar uma nova refêrencia geográfica";
        }
        if (utilitario.includes('CREATE_POINT_BUS')) {
            html += "Clique em uma posição no mapa para criar um novo ponto de ônibus";
        }
        return html;
    };

    /**
     * mapEvent - Evento emitido pelo onDragOut
     * id - id no array pontosgpsselecionado.
     */
    onDragEndPontosGpsSelecionado = (mapEvent, pontoGps) => {
        const { lngLat } = mapEvent;
        const { lng, lat } = lngLat;
        let { pontosGpsSelecionados } = this.state;
        pontoGps.latitude = lat;
        pontoGps.longitude = lng;
        pontosGpsSelecionados.set(pontoGps.id, pontoGps);
        this.setState({
            pontosGpsSelecionados
        });
    };
    onDragEndPontosGps = (mapEvent, pontoGps) => {
        const { lngLat } = mapEvent;
        const { lng, lat } = lngLat;
        let { pontosGps } = this.state;
        if (pontoGps) {
            pontoGps.latitude = lat;
            pontoGps.longitude = lng;
            pontosGps.set(pontoGps.id, pontoGps);
            this.setState({
                pontosGps
            });
        }
    };

    removerPontoGpsSelecionado = id => {
        let { pontosGps, pontosGpsSelecionados } = this.state;
        let pontoMarcador = pontosGpsSelecionados.get(id);
        pontosGpsSelecionados.delete(id);
        pontosGps.set(id, pontoMarcador);
        this.setState({
            pontosGps,
            pontosGpsSelecionados,
            marcadorSelecionado: undefined
        });
    };

    //Seleciona um ponto Gps já vinculado a um desenho de linha.
    onSelectPontoGpsSelecionado = ponto => {
        const { marcadorSelecionado } = this.state;

        this.setState({
            marcadorSelecionado:
                !marcadorSelecionado ||
                    (marcadorSelecionado && ponto.id !== marcadorSelecionado.id)
                    ? ponto
                    : undefined
        });
    };

    criarPontoEntreaRota = (evt, index) => {
        const { lng, lat } = evt.lngLat;

        let { pontosGpsSelecionados } = this.state;
        let array = [...pontosGpsSelecionados.values()];

        array.splice(index + 1, 0, {
            id: firebase
                .firestore()
                .collection('locTest')
                .doc().id,
            deviceid: this.state.deviceId,
            latitude: lat,
            longitude: lng
        });

        pontosGpsSelecionados = new Map(array.map(obj => [obj.id, obj]));
        this.setState({
            pontosGpsSelecionados
        });
    };

    adicionaMarcadorMapa = ({ latitude, longitude, selecionado }) => {
        let { pontosGps, pontosGpsSelecionados, utilitario } = this.state;
        let _ref = firebase
            .firestore()
            .collection('locTest')
            .doc();

        const mark = {
            id: _ref.id,
            deviceid: this.state.deviceId,
            latitude,
            longitude
        };

        if (!selecionado) {
            pontosGps.set(_ref.id, mark);
        }
        if (selecionado) {
            pontosGpsSelecionados.set(_ref.id, mark);
        }

        // 2022 - CODIGO INUTIL SE NAO TIVER O TRATAMENTO FICA MAIS FACIL CRIIAR LINHAS E NÃO GERA DUVIDAS
        // const travadoIdx = utilitario.findIndex(u => u === 'LOCK');
        // if (travadoIdx === -1) {
        //     delete utilitario[travadoIdx];
        //     delete utilitario[utilitario.findIndex(u => u === 'ADD_POINT_GPS')];
        // }

        this.setState({
            pontosGps,
            pontosGpsSelecionados,
            utilitario: _.compact(utilitario)
        });

        return selecionado
            ? pontosGpsSelecionados.get(_ref.id)
            : pontosGps.get(_ref.id);
    };

    criarPontoOnibus = async ({ latitude, longitude }) => {
        if(window.confirm(`Deseja realmente criar um ponto de ônibus nesta posição ?`)) {
            const db = firebase.firestore();
            const refPonto = db.collection('pontos').doc();
            await refPonto.set({
                id: refPonto.id,
                date: dayjs().unix(),
                velocidade_via: 40,
                bairro: '',
                latitude,
                longitude,
            });
            this.setState({
                utilitario: [],
            })
        }
    };

    removerPontoMarcado = ponto =>
        this.setState({
            pontosRota: this.state.pontosRota.filter(p => p !== ponto)
        });

    trocarPosicaoPontoEmbarque = (currentPos, newPos) => {
        let { pontosRota } = this.state;
        if (!pontosRota[newPos]) {
            return;
        }

        let antigo = pontosRota[currentPos];
        let novo = pontosRota[newPos];

        pontosRota[newPos] = antigo;
        pontosRota[currentPos] = novo;
        this.setState({ pontosRota });
    };

    /**
     * Caso o atributo ' changeLineView' vier como verdadeiro após a minificação já sera exposto na tela as alterações.
     * Caso seja falso somente irá minificar e retornar o resultado da minificação.
     */
    minificarRotaGps = (changeLineView = true) => {
        const antigo = Object.assign({}, this.state.pontosGpsSelecionados);
        let minificado = [...this.state.pontosGpsSelecionados.values()]
            .map((pos, i) => {
                if (i % 2 === 0) {
                    return pos;
                }
                return null;
            })
            .filter(_ => _);
        minificado = new Map(minificado.map(pos => [pos.id, pos]));
        if (changeLineView) {
            this.setState({
                pontosGpsSelecionados: minificado,
                restore: antigo
            });
            return minificado;
        }
        return minificado;
    };

    permiteSalvar = () => {

        const { pontosGpsSelecionados, pontosRota, nomeRota, salvando } = this.state;
        if (salvando) {
            return false;
        }
        if (pontosGpsSelecionados.size <= 1) {
            return false;
        }
        if (pontosRota.length === 0) {
            return false;
        }

        if (!nomeRota) {
            return false;
        }
        return true;
    };

    setUtilitario = (utilitarioClicado) => {

        this.setState(() => {

            if (this.state.utilitario.includes(utilitarioClicado)) {
                return {
                    utilitario: this.state.utilitario.filter(u => u !== utilitarioClicado)
                }
            }

            return {
                utilitario: [utilitarioClicado]
            };
        })

    };

    criarObjetoSetor(nome = `S${this.state.setores.length}`, pontosId = [], defaultInc = 0) {
        return {
            nome,
            defaultInc,
            pontoInicialId: null,
            pontoFinalId: null,
            pontos: pontosId.map(id => ({
                id,
                inc: defaultInc
            })),
            horarios: new Map()
        }
    }

    criarNovoSetor = () => {
        this.setState(state => {
            const { setores } = state;
            let newSetores = state.setores;
            
            // let todosPontosId = pontosRota.map(({ id }) => id);
            // if (state.setores.length === 0) {
            //     newSetores = [
            //         this.criarObjetoSetor(`S1`, [], 0)
            //     ];
            // }

            // if (state.setores.length === 1) {
            //     newSetores = [
            //         this.criarObjetoSetor(`S1`, todosPontosId.slice(0, todosPontosId.length / 2), 0),
            //         this.criarObjetoSetor(`S2`, todosPontosId.slice(todosPontosId.length / 2), 10),
            //     ]
            // }

            // if (state.setores.length >= 1) {
                newSetores.push(this.criarObjetoSetor(`S${Number(setores.length) + 1}` , [], 20));
            // }

            return {
                setores: newSetores
            }

        })
    }

    updateMinutesOfSetorIdxAndPontoIdx(value,setorIdx, pontoIdx) {
        if(Number(value) <= -1) {
            return;
        }
        this.setState(state => {
            let newSetores = state.setores;
            newSetores[setorIdx].pontos[pontoIdx].inc = value;
            return {
                setores: newSetores
            }
        })
    }

    calcHHMMAndSumWithMinutes(hourAndMinute, minutes) {
        return minutes === 0 ?
            hourAndMinute :
            dayjs(`2000-01-01 ${hourAndMinute}:00`).add(minutes, `minutes`).format(`HH:mm`);
    }

    onChangeIdxSetorSelecionado(idx) {

        const { setores, pontosRota } = this.state;

        if( idx > 0 && setores[idx].pontoInicialId == null && setores[idx-1].pontoFinalId) {
            let setorAnterior = setores[idx-1];
            setores[idx].pontoInicialId = pontosRota[pontosRota.findIndex(pr => pr.id === setorAnterior.pontoFinalId) + 1].id;
        }

        this.setState({ 
            idxSetorSelecionado: idx,
            setores: setores,
        });
    }



    onFileKlm = (event) => {

        const file = event.target.files[0];
        var reader = new FileReader();
        
        let { pontosGpsSelecionados } = this.state;
        reader.onload = (event) => {
            const content = event.target.result;

            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(content,"text/xml");
            const xmlCoordinates = xmlDoc.getElementsByTagName(`coordinates`);

            if(xmlCoordinates.length >=3 ) {

                const extractCoordinates = text => text.innerHTML.trim().replace(/(\r\n|\n|\r)/gm, "").replace('\n', '').replace(/ /g, '').split(`,0`)

                const coordinates = [
                    ...extractCoordinates(xmlCoordinates[0]),
                    ...extractCoordinates(xmlCoordinates[2]),
                    ...extractCoordinates(xmlCoordinates[1])
                ].filter(hasValue => hasValue);

                coordinates.forEach((pos, i) => {

                    let [ longitude, latitude ] = pos.split(",");

                    pontosGpsSelecionados.set(i, {
                        id: i,
                        longitude: longitude,
                        latitude: latitude,
                    })
                });

                this.setState({
                    
                });


            } else {
                alert(`Insira um arquivo do MellisaTur KML com somente (Export as KML instead of KMZ. Does not support all icons)`);
            }
        };
        reader.readAsText(file);
    }

    render() {
        const { history } = this.props;
        const {
            utilitario,
            pontos,
            nomeRota,
            devices,
            deviceId,
            pontosGps,
            pontosGpsSelecionados,
            loadingConsulta,
            startDate,
            endDate,
            marcadorSelecionado,
            tab,
            pontosRota,
            snackbar,
            pontoRotaSelecionado,
            loading,
            cor,
            setores,
            idxSetorSelecionado,
            horarioExemplo,
            mapaCentro,
            zoom,
        } = this.state;
        const { uidRota } = this.props.match.params;

        let array = [...pontosGpsSelecionados.values()];

        if (loading) {
            return <h1>Carregando</h1>;
        }

        const existePontoSemSetor = pontosRota.length !== setores.reduce((prev,cur)=> prev + cur.pontos.length, 0);

        const bloquearSalvar = this.permiteSalvar() === false || existePontoSemSetor;

        return (
            <React.Fragment>
                <Grid container>

                    <Box width="100%" display="flex" justifyContent="space-evenly" mt={3} >

                        <Button
                            onClick={() => history.replace('/Rotas')}
                        >
                            Voltar
                        </Button>

                        <Box textAlign="center">
                            <h2>{uidRota ? 'Editando' : 'Cadastro'}&nbsp;de uma nova rota de ônibus</h2>
                        </Box>

                        <Button
                            disabled={bloquearSalvar}
                            color="primary"
                            variant="contained"
                            onClick={this.salvar}
                        >
                            Salvar e ativar
                        </Button>

                    </Box>
                </Grid>

                <Grid container>
                    {!loadingConsulta && (
                        <React.Fragment>
                            <Grid item lg={2} xs={2}>
                                {devices.size !== 0 ? (
                                    <SelectItem
                                        disabled={false}
                                        refToState="deviceId"
                                        value={this.state.deviceId}
                                        items={[...devices.values()].map(
                                            el => ({
                                                value: el.id,
                                                desc: el.nome ? el.nome : el.id
                                            })
                                        )}
                                        onChange={this.onChange}
                                    />
                                ) : null}
                            </Grid>
                            <Grid item lg={3} xs={3}>
                                {deviceId && (
                                    <DateTimePicker
                                        onChange={this.onChange}
                                        refToState="startDate"
                                        label="Selecione uma data Inicial"
                                        value={startDate}
                                    />
                                )}
                            </Grid>
                            {deviceId && (
                                <Grid
                                    item
                                    lg={1}
                                    xs={1}
                                    style={{
                                        padding: '13px',
                                        paddingTop: '20px'
                                    }}
                                >
                                    Até
                                </Grid>
                            )}
                            <Grid item xs={3}>
                                {deviceId && (
                                    <DateTimePicker
                                        onChange={this.onChange}
                                        refToState="endDate"
                                        label="Selecione uma data final"
                                        value={endDate}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={1} lg={1}>
                                {deviceId && (
                                    <Button
                                        style={{ top: '10px' }}
                                        onClick={this.consultarRotas}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Consultar
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={1} lg={1} />
                        </React.Fragment>
                    )}
                </Grid>
                <br />
                <Divider />
                <br />


                <Grid container>
                    <Tabs
                        value={tab}
                        onChange={(evt, tab) => {
                            this.setState(state => {
                                let update = {
                                    tab
                                };
                                if (tab !== 0) {
                                    update.utilitario = state.utilitario.filter(
                                        u =>
                                            u !==
                                            'ON_CLICK_INTERVAL_POINTS_CREATE_POINT'
                                    );
                                }
                                return update;
                            });

                            this.setState({ tab });
                        }}
                        variant="fullWidth"
                        style={{ width: '100%' }}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Rota" icon={<Directions />} />
                        <Tab label="Pontos" icon={<NotListedLocation />} />
                        <Tab label="Setores" icon={<LowPriorityIcon />} />
                        <Tab disabled={existePontoSemSetor} label="Horários" icon={<DepartureBoard />} />
                    </Tabs>
                    <Divider width="100%" ></Divider>


                    <Grid item lg={12} xs={12} display="flex" >
                        {tab === 0 && <Box display="flex" justifyContent="space-evenly" alignItems="center" p={4} >
                            <Box flex={1} >
                                <TextField
                                    width="300px"
                                    variant="filled"
                                    id="standard-full-width"
                                    label="Nome"
                                    placeholder="Nome da rota"
                                    fullWidth
                                    value={nomeRota}
                                    margin="normal"
                                    onChange={e =>
                                        this.setState({
                                            nomeRota: e.target.value
                                        })
                                    }
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Box>

                            <input
                                type="file"
                                style={{ display: 'none' }}
                                id="input-klm-file"
                                onChange={this.onFileKlm}
                            />
                            {/* accept="image/*" */}
                            

                            <Box flex={2} display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
                                <Box mb={2} >
                                    <Button variant="contained" color={utilitario.includes('ADD_POINT_GPS') ? 'primary' : 'default'} onClick={() => this.setUtilitario('ADD_POINT_GPS')} >
                                        <Place />
                                    </Button>
                                    <Button variant="contained" color={utilitario.includes('ON_CLICK_INTERVAL_POINTS_CREATE_POINT') ? 'primary' : 'default'} onClick={() => this.setUtilitario('ON_CLICK_INTERVAL_POINTS_CREATE_POINT')} >
                                        <CallMerge />
                                    </Button>
                                    <Button variant="contained" color="default" onClick={() => {
                                        document.getElementById('input-klm-file').click()
                                    }} >
                                        KML File
                                    </Button>
                                </Box>
                                <Typography>{this.mostrarMensagemUtilitario()}</Typography>
                            </Box>

                            <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center">
                                <Typography>Cor da rota:&nbsp;</Typography>
                                <ButtonColorPicker
                                    color={cor}
                                    onChange={cor => this.setState({ cor })}
                                />
                                {pontosGpsSelecionados.size > 2 &&
                                    utilitario.includes(
                                        'ON_CLICK_INTERVAL_POINTS_CREATE_POINT'
                                    ) && (
                                        <Box textAlign="center" color="red">
                                            <Button
                                                variant="contained"
                                                onClick={this.minificarRotaGps}
                                                fontSize="small"
                                            >
                                                Minificar
                                            </Button>
                                        </Box>
                                    )}
                                {marcadorSelecionado && (
                                    <Button
                                        onClick={() =>
                                            this.removerPontoGpsSelecionado(
                                                marcadorSelecionado.id
                                            )
                                        }
                                        variant="contained"
                                        color="secondary"
                                    >
                                        <Delete />
                                    </Button>
                                )}
                            </Box>
                        </Box>
                        }


                        {tab === 1 && <Box display="flex" justifyContent="space-evenly" alignItems="center" p={4} >
                            <Box flex={2} display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
                                <Box mb={2} >
                                    <Button variant="contained" color={utilitario.includes('CREATE_POINT_BUS') ? 'primary' : 'default'} onClick={() => this.setUtilitario('CREATE_POINT_BUS')} >
                                        <Place />
                                    </Button>
                                </Box>
                                <Typography>{this.mostrarMensagemUtilitario()}</Typography>
                            </Box>
                        </Box>}

                        <Box display="flex" width="100%" >
                            {(tab === 0 || tab === 1 || tab === 2) &&
                                <MapBox
                                    style={StylesMapBox} // eslint-disable-next-line
                                    containerStyle={{
                                        height: '60vh',
                                        width: '100%'
                                    }}
                                    center={mapaCentro}
                                    zoom={zoom}
                                    // maxBounds={[
                                    //     [-52.500299054383206, -24.27988414894527],
                                    //     [-52.26913534007542, -23.822796478590945]
                                    // ]}
                                    onStyleLoad={map => map.setCenter(initialCenter)}
                                    onClick={(map, evt) => {

                                        const { utilitario } = this.state;

                                        if (utilitario.includes('ADD_POINT_GPS')) {
                                            const { lat, lng } = evt.lngLat;
                                            this.adicionaMarcadorMapa({
                                                latitude: lat,
                                                longitude: lng
                                            });
                                            return;
                                        }

                                        if (utilitario.includes('CREATE_POINT_BUS')) {
                                            const { lat, lng } = evt.lngLat;
                                            this.criarPontoOnibus({
                                                latitude: lat,
                                                longitude: lng
                                            });
                                            return;
                                        }

                                    }}
                                    movingMethod="jumpTo"
                                >
                                    {tab === 0 && pontosGps.size !== 0 && (
                                        <LayerCircle
                                            id="pontos_gps"
                                            features={[...pontosGps.values()]}
                                            onClick={this.onClickMarker}
                                            onDragEnd={this.onDragEndPontosGps}
                                        />
                                    )}
                                    {tab === 0 && pontosGpsSelecionados.size !== 0 && (
                                        <LayerCircle
                                            id="pontos_gps_selecionados"
                                            features={[
                                                ...pontosGpsSelecionados.values()
                                            ].filter(
                                                pontoGps =>
                                                    pontoGps &&
                                                    (!marcadorSelecionado ||
                                                        pontoGps.id !==
                                                        marcadorSelecionado.id)
                                            )}
                                            color="#FCCF7C"
                                            onClick={this.onSelectPontoGpsSelecionado}
                                            onDragEnd={
                                                this.onDragEndPontosGpsSelecionado
                                            }
                                        />
                                    )}
                                    {tab === 0 && marcadorSelecionado && (
                                        <React.Fragment>
                                            <LayerCircle
                                                id="marcador_selecionado"
                                                features={[
                                                    ...pontosGpsSelecionados.values()
                                                ].filter(
                                                    pontoGps =>
                                                        pontoGps &&
                                                        marcadorSelecionado &&
                                                        marcadorSelecionado.id ===
                                                        pontoGps.id
                                                )}
                                                color={cor}
                                                onClick={() =>
                                                    this.setState({
                                                        marcadorSelecionado: undefined
                                                    })
                                                }
                                                onDragEnd={
                                                    this.onDragEndPontosGpsSelecionado
                                                }
                                            />
                                        </React.Fragment>
                                    )}
                                    {array.length > 1 &&
                                        utilitario.includes(
                                            'ON_CLICK_INTERVAL_POINTS_CREATE_POINT'
                                        ) &&
                                        array.map((cur, i) => {
                                            let p1 = array[i];
                                            let p2 = array[i + 1];
                                            if (!p2) {
                                                return null;
                                            }
                                            let linha = turf.lineString([
                                                [p1.longitude, p1.latitude],
                                                [p2.longitude, p2.latitude]
                                            ]).geometry;
                                            return (
                                                <LinhaLayer
                                                    key={i}
                                                    rota={linha}
                                                    color={cor}
                                                    width={10}
                                                    onClick={evt =>
                                                        this.criarPontoEntreaRota(
                                                            evt,
                                                            i
                                                        )
                                                    }
                                                />
                                            );
                                        })}
                                    {array.length > 1 &&
                                        !utilitario.includes(
                                            'ON_CLICK_INTERVAL_POINTS_CREATE_POINT'
                                        ) && (
                                            <LinhaLayer
                                                showIndicators={true}
                                                color={cor}
                                                rota={
                                                    turf.lineString(
                                                        array.map(
                                                            ({
                                                                longitude,
                                                                latitude
                                                            }) => [longitude, latitude]
                                                        )
                                                    ).geometry
                                                }
                                            />
                                        )}
                                
                                    <React.Fragment>
                                        {tab === 1 && (
                                            <LayerPontos
                                                id="PontosNaoMarcados"
                                                type={1}
                                                array={
                                                    // Remover registros que estejam iguais aos da variavel 'pontosRota'}
                                                    _.difference(
                                                        [...pontos.values()],
                                                        pontosRota
                                                    )
                                                }
                                                onClick={this.onClickMarkerPontobus}
                                            />
                                        )}

                                        <LayerPontos
                                            id="PontosMarcados"
                                            type={2}
                                            array={pontosRota}
                                            onClick={(pontoRotaSelecionado, idx) => {
                                                if (tab === 1 || tab === 2) {
                                                    this.setState({
                                                        pontoRotaSelecionado
                                                    });

                                                    if(tab === 2) {

                                                        const setorSelecionado = setores[idxSetorSelecionado];
                                                        let newSetores = setores;

                                                        //se ja estiver selecionado nao pode selecionar dnv                                                    
                                                        let pontoJaRestriadoEmUmSetor = setores.findIndex(s => s.pontos.findIndex(({id}) => id === pontoRotaSelecionado.id) !== -1 ) !== -1;
                                                        if(pontoJaRestriadoEmUmSetor) {
                                                            return;
                                                        }

                                                        if(setorSelecionado.pontoInicialId === null || setorSelecionado.pontoFinalId === null) {

                                                            if(setorSelecionado.pontoInicialId == null) {
                                                                newSetores[idxSetorSelecionado].pontoInicialId = pontoRotaSelecionado.id;
                                                                this.setState({ setores: newSetores });
                                                            } else if(setorSelecionado.pontoFinalId == null) {
                                                                newSetores[idxSetorSelecionado].pontoFinalId = pontoRotaSelecionado.id;

                                                                const { pontosRota } = this.state;

                                                                let idxInicio = pontosRota.findIndex(pr => pr.id === newSetores[idxSetorSelecionado].pontoInicialId);
                                                                let idxFim = pontosRota.findIndex(pr => pr.id === newSetores[idxSetorSelecionado].pontoFinalId) + 1;

                                                                if(idxFim < idxInicio) {
                                                                    
                                                                    newSetores[idxSetorSelecionado].pontos = pontosRota.slice(idxInicio, pontosRota.length).concat(pontosRota.slice(0, idxFim)).map(ponto => ({
                                                                        id: ponto.id,
                                                                        inc: 0,
                                                                    }));;

                                                                } else {
                                                                    newSetores[idxSetorSelecionado].pontos = pontosRota.slice(idxInicio, idxFim).map(ponto => ({
                                                                        id: ponto.id,
                                                                        inc: 0,
                                                                    }));
                                                                }

                                                                


                                                                // newSetores[idxSetorSelecionado].pontos = [];

                                                                // const { pontoFinalId, pontoInicialId } = newSetores[idxSetorSelecionado];
                                                                // pontosRota.reduce((prev,cur) => {

                                                                //     let objPonto = {
                                                                //         id: cur.id,
                                                                //         inc: 0,
                                                                //     }

                                                                //     if(prev == null) {
                                                                //         if(cur.id == pontoInicialId) {
                                                                //             newSetores[idxSetorSelecionado].pontos.push(objPonto);
                                                                //             return 1
                                                                //         }
                                                                //     }

                                                                //     if(prev == 1) {
                                                                //         if(cur.id != pontoFinalId) {
                                                                //             newSetores[idxSetorSelecionado].pontos.push(objPonto);
                                                                //         }
                                                                //         if(cur.id == pontoFinalId) {
                                                                //             return 2;
                                                                //         }                                                                        {}
                                                                //     }

                                                                //     return false

                                                                // }, null);

                                                                //Se ainda tiver algum ponto sem setor automaticamente já cria outro setor.
                                                                // console.log(pontosRota.filter(pr => newSetores.findIndex(s => s.pontos.findIndex(({id}) => id === pr.id) === -1 )));
                                                                if(pontosRota.length !== newSetores.reduce((prev,cur)=> prev + cur.pontos.length, 0)) {
                                                                    this.criarNovoSetor();
                                                                    this.onChangeIdxSetorSelecionado(this.state.idxSetorSelecionado + 1)
                                                                }

                                                                this.setState({ pontoRotaSelecionado: undefined });

                                                            }
                                                            this.setState({ setores: newSetores });
                                                        }

                                                    }

                                                }
                                            }}
                                        />

                                        {(tab === 1 || tab === 2) &&
                                            pontosRota &&
                                            pontosRota.map((ponto, idx) => (
                                                <Marker
                                                    key={idx}
                                                    coordinates={[
                                                        ponto.longitude,
                                                        ponto.latitude
                                                    ]}
                                                    anchor="bottom"
                                                    style={{ zIndex: ponto === pontoRotaSelecionado ? 1001 : 1000 }}
                                                    width="300px"
                                                >


                                                    {(() => {

                                                        if (ponto === pontoRotaSelecionado && tab === 1) {
                                                            return (
                                                                <div
                                                                    style={{
                                                                        background: 'white',
                                                                        paddingTop: '5px',
                                                                        zIndex: 1000
                                                                    }}
                                                                >
                                                                    <Grid container>
                                                                        <Grid item md={4} >
                                                                            <Box
                                                                                textAlign="center"
                                                                                color="red"
                                                                            >
                                                                                <Delete
                                                                                    onClick={() =>
                                                                                        this.removerPontoMarcado(
                                                                                            ponto
                                                                                        )
                                                                                    }
                                                                                    fontSize="small"
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item md={4} />
                                                                        <Grid
                                                                            md={4}
                                                                            item
                                                                        >
                                                                            <Box textAlign="center">
                                                                                <Close
                                                                                    onClick={() =>
                                                                                        this.setState(
                                                                                            {
                                                                                                pontoRotaSelecionado: undefined
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    fontSize="small"
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <ButtonGroup size="small">
                                                                        <Button
                                                                            onClick={() =>
                                                                                this.trocarPosicaoPontoEmbarque(
                                                                                    idx,
                                                                                    idx - 1
                                                                                )
                                                                            }
                                                                            variant="contained"
                                                                            color="secondary"
                                                                        >
                                                                            <strong>-</strong>
                                                                        </Button>
                                                                        <Button>
                                                                            {idx + 1}
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() =>
                                                                                this.trocarPosicaoPontoEmbarque(
                                                                                    idx,
                                                                                    idx + 1
                                                                                )
                                                                            }
                                                                            variant="contained"
                                                                            color="primary"
                                                                        >
                                                                            <strong>+</strong>
                                                                        </Button>
                                                                    </ButtonGroup>
                                                                </div>
                                                            )
                                                        }

                                                        if ( tab === 2) {

                                                            let setorSelecionado = this.state.setores[idxSetorSelecionado];

                                                            const possuiInicioEFimDoSetor = setorSelecionado && (setorSelecionado.pontoInicialId && setorSelecionado.pontoFinalId);
                                                            let setorIdx = setores.findIndex(s => s.pontos.findIndex(({id}) => id === ponto.id) !== -1);

                                                            if(ponto === pontoRotaSelecionado && possuiInicioEFimDoSetor) {

                                                                // let setorSelecionado = setores[this.state.idxSetorSelecionado];

                                                                let setorIdx = setores.findIndex(s => s.pontos.findIndex(({id}) => id === pontoRotaSelecionado.id) !== -1);
                                                                let setorDoPonto = setores[setorIdx];
                                                                
                                                                return (
                                                                    <div
                                                                        style={{ background: 'white', paddingTop: '5px', zIndex: 1000, width: '350px' }}
                                                                    >
                                                                        <Box display="flex" width="100%" justifyContent="space-between" pr={2} >

                                                                            {setorIdx === -1 && <Box p={2} style={{color:`orange`, fontSize:`24px`}} >
                                                                                Selecione um setor
                                                                            </Box>}

                                                                            {setorIdx !== -1 && (() => {

                                                                                let pontoIdx = setorDoPonto.pontos.findIndex(p => p.id === pontoRotaSelecionado.id);
                                                                                let pontoOfSetor = setorDoPonto.pontos[pontoIdx];

                                                                                let qtdMinutos = setorDoPonto.pontos.find(({id}) => id === pontoRotaSelecionado.id)?.inc;

                                                                                return <Box display="flex" alignItems="center" p={1} >

                                                                                    <Box display="flex" flexDirection="column" >
                                                                                        <span style={{fontSize: '20px'}} >{horarioExemplo} + {qtdMinutos} minutos = <b>{this.calcHHMMAndSumWithMinutes(horarioExemplo, qtdMinutos)}</b></span>
                                                                                        <Box display="flex" alignItems="center" >
                                                                                            <IconButton onClick={() => this.updateMinutesOfSetorIdxAndPontoIdx(Number(pontoOfSetor.inc) - 1, setorIdx, pontoIdx)} > - </IconButton>
                                                                                            <input
                                                                                                style={{height: `min-content`, margin: '0 5px', maxWidth: `60px`, fontSize: '25px'}}
                                                                                                label="Incrementar minutos"
                                                                                                value={qtdMinutos}
                                                                                                onChange={evt => {
                                                                                                    evt.persist();
                                                                                                    this.updateMinutesOfSetorIdxAndPontoIdx(evt.target.value, setorIdx, pontoIdx);
                                                                                                    // updateValue(evt.target.value);
                                                                                                }}
                                                                                                type="number"
                                                                                            />     
                                                                                            <IconButton onClick={() => this.updateMinutesOfSetorIdxAndPontoIdx(Number(pontoOfSetor.inc) + 1, setorIdx, pontoIdx)} > + </IconButton>
                                                                                        </Box>
                                                                                    </Box>
                                                                                
                                                                            </Box>
                                                                            })()}

                                                                            <IconButton
                                                                                key="close"
                                                                                aria-label="close"
                                                                                color="inherit"
                                                                                onClick={() => this.setState({ pontoRotaSelecionado: undefined })}
                                                                            >
                                                                                <Close  fontSize="small" />
                                                                            </IconButton>
                                                                        </Box>

                                                                        {setorIdx === -1 && <List >
                                                                            {setores.map((setor, idx) => (
                                                                                <ListItem key={setor.nome + idx} role={undefined} dense button onClick={() => {
                                                                                    this.setState(state => {
                                                                                        
                                                                                        let newSetores = state.setores;

                                                                                        let setorSelecionado = this.state.setores[this.idxSetorSelecionado];

                                                                                        if(setorSelecionado) {
                                                                                            newSetores[setorIdx].pontos = setorSelecionado.pontos.filter(({ id }) => id !== pontoRotaSelecionado.id);
                                                                                        }

                                                                                        if(idx !== setorIdx) {
                                                                                            let { defaultInc } = newSetores[idx];
                                                                                            newSetores[idx].pontos.push({
                                                                                                id: pontoRotaSelecionado.id,
                                                                                                inc: defaultInc,
                                                                                            });
                                                                                        }

                                                                                        return {
                                                                                            setores: newSetores
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                                    <Checkbox
                                                                                        checked={setorIdx === idx}
                                                                                        tabIndex={-1}
                                                                                        disableRipple
                                                                                    />
                                                                                    <ListItemText primary={setor.nome} />
                                                                                    <ListItemSecondaryAction>
                                                                                    </ListItemSecondaryAction>
                                                                                </ListItem>
                                                                            ))}
                                                                            <ListItem role={undefined} dense button onClick={this.criarNovoSetor}>

                                                                                <IconButton
                                                                                    key="add"
                                                                                    aria-label="Add"
                                                                                    color="inherit"
                                                                                >
                                                                                    <Add />
                                                                                </IconButton>

                                                                                <ListItemText primary={'Criar novo setor'} />
                                                                                <ListItemSecondaryAction>
                                                                                </ListItemSecondaryAction>
                                                                            </ListItem>

                                                                        </List>}
                                                                    </div>
                                                                )
                                                            } else {

                                                                if(!possuiInicioEFimDoSetor && setorSelecionado) {
                                                                    const possuiInicio = !!setorSelecionado.pontoInicialId;
                                                                    const possuiFim = !!setorSelecionado.pontoFinalId;

                                                                    if(possuiInicio && setorSelecionado.pontoInicialId === ponto.id) {
                                                                        return <h3 style={{color:`green`}} >ínicio</h3>
                                                                    }

                                                                    if(possuiFim) {
                                                                        return <h3 style={{color:`green`}} >fim</h3>
                                                                    }

                                                                    if(setorIdx !== -1) {
                                                                        return <span role="img" aria-label="ok" >🔰</span>;
                                                                    }

                                                                    return <span role="img" aria-label="avaiable" >🔳</span>
                                                                }

                                                                // let isPontoInicial = setorSelecionado.pontoInicialId == ponto.id;
                                                                // let isPontoFinal = setorSelecionado.pontoFinalId == ponto.id;

                                                                if(setorIdx === -1) {
                                                                    return <h3 key="empty" style={{ color: 'orange' }} >Sem Setor</h3>
                                                                }

                                                                let str = `${setores[setorIdx].nome} P${idx+1}`
                                                                return <h3 key={'marker_ponto_setor_' + setorIdx} style={{ color: 'yellow' }}> {str} </h3>    
                                                            }
                                                            
                                                        }

                                                        return <h2 style={{ color: 'red	' }}> {idx+1} </h2>


                                                    })()}

                                                </Marker>
                                            ))}
                                    </React.Fragment>
                                </MapBox>
                            }
                            
                            {(tab === 2 || tab === 3) && <Box width="100%" p={1} >

                                <Tabs
                                    variant="scrollable"
                                    scrollButtons="on"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    value={this.state.idxSetorSelecionado}
                                    onChange={(event, idx) => {
                                        if( (setores.length) === idx ) {
                                            this.criarNovoSetor();
                                            return;
                                        }

                                        this.onChangeIdxSetorSelecionado(idx);
                                        
                                    }}
                                >
                                    {setores.map(setor => <Tab key={setor.nome} label={`Setor`} icon={<h2>{setor.nome}</h2>} />)}
                                    {/* {tab === 2 && <Tab key={'novoSetor'} label="Novo Setor" />} */}
                                </Tabs>

                                { tab === 2 && (() => {

                                    const setor = setores[idxSetorSelecionado];

                                    if(setor.pontoInicialId == null) {
                                        return <Box p={3} > 
                                            <Typography variant="h5" align="center" >
                                                Clique em um ponto para
                                                <br/>
                                                definir o ponto inicial {setor.pontoInicialId}
                                                <br/>
                                                <img src={IconePontoOnibus} alt="" />
                                            </Typography>
                                        </Box>
                                    }

                                    if(setor.pontoFinalId == null) {
                                        return <Box p={3} > 
                                            <Typography variant="h5" align="center" >
                                                Clique no último ponto deste setor
                                                <br/>
                                                <img src={IconePontoOnibus} alt="" />
                                            </Typography>
                                        </Box>
                                    }


                                    return <Box display="flex" height="48vh" overflow="scroll"  >
                                                <List style={{width: '100%'}}>
                                                    {setor.pontos.map((ponto, pontoIdx) => 
                                                        <Box key={ponto.id + pontoIdx}  >
                                                            <ListItem role={undefined} dense width="100%" >
                                                                
                                                                    <Box width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" >

                                                                        <div style={{ fontSize: `20px`, fontWeight: `bold`}} >
                                                                            {`${setores[idxSetorSelecionado].nome} P${pontoIdx}`}
                                                                        </div>
                                                                
                                                                        <div>
                                                                            <span style={{fontSize: '19px', fontStyle:'italic'}} >12:00</span>
                                                                            &nbsp;⏩&nbsp;
                                                                            <span style={{fontSize: '25px', fontWeight: 'bold'}} > {this.calcHHMMAndSumWithMinutes(horarioExemplo, ponto.inc)}  </span>
                                                                        </div>

                                                                        <div>
                                                                            <span>Incrementar</span>
                                                                            <input
                                                                                style={{height: `min-content`, maxWidth: `60px`, margin: '0 5px'}}
                                                                                label="Incrementar minutos"
                                                                                value={ponto.inc}
                                                                                onChange={evt => {
                                                                                    evt.persist();
                                                                                    this.updateMinutesOfSetorIdxAndPontoIdx(evt.target.value, idxSetorSelecionado, pontoIdx);
                                                                                }}
                                                                                type="number"
                                                                            />
                                                                            <span>minutos</span>
                                                                            <IconButton onClick={() => this.updateMinutesOfSetorIdxAndPontoIdx(Number(ponto.inc) + 1, idxSetorSelecionado, pontoIdx)} > + </IconButton>
                                                                            <IconButton onClick={() => this.updateMinutesOfSetorIdxAndPontoIdx(Number(ponto.inc) - 1, idxSetorSelecionado, pontoIdx)} > - </IconButton>
                                                                        </div>
                                                                        
                                                                    </Box>
                                                                    
    
                                                            </ListItem>
                                                            <Divider></Divider>
                                                        </Box>
                                                        )
                                                    }
                                                </List>

                                    </Box>
                                })()}

                                {setores[0].pontos.length !== 0 && <Box width="100%" justifyContent="flex-end" >
                                    <Button onClick={() => {
                                        this.setState({
                                            setores: [
                                                this.criarObjetoSetor('S1', [], 0),
                                            ],
                                            idxSetorSelecionado: 0, 
                                        })
                                    }} >Resetar Setores e Horários</Button>     
                                </Box>}
                                


                            </Box>}
                        </Box>

                    </Grid>
                    <Grid container>
                        {tab === 1 && (
                            <Grid item md={12} sm={12} lg={12}>
                                <Box
                                    padding="2em"
                                    textAlign="center"
                                    fontWeight="bold"
                                >
                                    Clique os pontos de embarque da qual a linha
                                    irá passar <br />
                                    Ao clicar em um ponto de embarque já
                                    selecionado , é possivel mudar a sua
                                    sequência/ordem.
                                </Box>
                            </Grid>
                        )}

                        {tab === 3 && (
                            <Grid item md={12} sm={12} lg={12}>
                                <Box p={4} >
                                    <HorariosOnibus
                                        horarios={setores[idxSetorSelecionado].horarios}
                                        onChange={responseHorarios => {
                                            let newSetores = setores;
                                            newSetores[idxSetorSelecionado].horarios = responseHorarios;
                                            this.setState({
                                                setores: newSetores
                                            })

                                        }}
                                    />
                                </Box>
                            </Grid>
                        )}

                    </Grid>
                </Grid>
                <Snack
                    variant={
                        snackbar && snackbar.variant ? snackbar.variant : 'info'
                    }
                    open={!!snackbar}
                    position={
                        snackbar && snackbar.position
                            ? snackbar.position
                            : 'right'
                    }
                    vertical={
                        snackbar && snackbar.vertical
                            ? snackbar.vertical
                            : 'top'
                    }
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() =>
                                this.setState({ snackbar: undefined })
                            }
                        >
                            <Close />
                        </IconButton>
                    ]}
                    message={
                        snackbar && snackbar.message ? snackbar.message : ''
                    }
                />
            </React.Fragment>
        );
    }
}

export default CriarRota;
