import React, { Component } from 'react';
import InputHorario from './InputHorario';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import { daysByBinary } from '../functions/data';
import Checkbox from '@material-ui/core/Checkbox';
import { DeleteSweep, LinkOff, Link } from '@material-ui/icons';
import _ from 'lodash';
import { FilledInput, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';

const stylesTableCellTH = {
    fontSize: '18px',
    textAlign: 'center',
    border: '1px solid #ccc',
    borderTop: 'none',
    borderBottom: 'none',
    padding: '0 0'
};

const stylesTableCellItem = {
    textAlign: 'center',
    border: '1px solid #ccc',
    borderTop: 'none',
    borderBottom: 'none',
    padding: 0
};

const stylesCheckbox = {
    padding: '5px',
    display: 'block'
};

const static_values_days = {
    domingo: 1, //domingo
    segunda: 2, //segunda
    terca: 4, //terça
    quarta: 8, //quarta
    quinta: 16, //quinta
    sexta: 32, //sexta
    sabado: 64, // sabado
    feriado: 128 //feriado
};

class HorariosOnibus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: '12:00',
            tipoDiaSelecionado: `DIA_UTIL`
        };
    }

    addTime = () => {
        const { time } = this.state;
        const { horarios } = this.props;
        let aux = time.split(':');
        if (
            parseInt(aux[0]) > 0 ||
            (aux[0] === '00' &&
                parseInt(aux[0]) <= 24 &&
                parseInt(aux[1]) > 0) ||
            (aux[1] === '00' && parseInt(aux[1]) <= 60)
        ) {
            horarios.set(time, 255);
        }
        this.props.onChange(horarios);
    };

    // getHorarios = () => {
    // 	return [...this.props.horarios.keys()].reduce((prev, current) => {
    // 		prev[current] = this.props.horarios.get(current);
    // 		return prev;
    // 	}, {});
    // };

    render() {
        let { horarios } = this.props;
        return (
            <React.Fragment>


                <Box display="flex" width="100%" justifyContent="space-between" >
                    <Typography variant="h4" >
                        Horário x Setor x Dia da Semana
                    </Typography>
                    <Box  >
                        <InputHorario
                            onEnter={this.addTime}
                            defaultValue="12:00"
                            onChange={e => this.setState({ time: e.target.value })}
                        />
                    </Box>
                    
                </Box>
                
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={stylesTableCellTH} />
                                <TableCell style={stylesTableCellTH}>
                                    Domingo
                                </TableCell>
                                <TableCell style={stylesTableCellTH}>
                                    Segunda Feira
                                </TableCell>
                                <TableCell style={stylesTableCellTH}>
                                    Terça Feira
                                </TableCell>
                                <TableCell style={stylesTableCellTH}>
                                    Quarta Feira
                                </TableCell>
                                <TableCell style={stylesTableCellTH}>
                                    Quinta Feira
                                </TableCell>
                                <TableCell style={stylesTableCellTH}>
                                    Sexta Feira
                                </TableCell>
                                <TableCell style={stylesTableCellTH}>
                                    Sabado
                                </TableCell>
                                <TableCell style={stylesTableCellTH}>
                                    {' '}
                                    Feriado
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {horarios.size === 0 && (
                                <TableRow>
                                    <TableCell
                                        style={{ padding: '1em 2em' }}
                                        colSpan={6}
                                    >
                                        Insira os horarios no campo acima !
                                    </TableCell>
                                </TableRow>
                            )}

                            {horarios.size !== 0 && (
                                <TableRow>
                                    <TableCell style={{ padding: '1em 2em' }}>
                                        Ações
                                    </TableCell>
                                    {Object.keys(static_values_days).map(
                                        (diaSemana, i4) => {
                                            const columnActive =
                                                [...horarios.values()].reduce(
                                                    (prev, current) =>
                                                        prev +
                                                        daysByBinary(current)[
                                                            diaSemana
                                                        ].active
                                                            ? 1
                                                            : 0,
                                                    0
                                                ) !== 0;
                                            return (
                                                <TableCell
                                                    key={i4}
                                                    style={{
                                                        padding: 0,
                                                        textAlign: 'center',
                                                        border:
                                                            '1px solid #ccc',
                                                        borderTop: 'none',
                                                        borderBottom: 'none'
                                                    }}
                                                >
                                                    <Button
                                                        onClick={() => {
                                                            if (columnActive) {
                                                                [
                                                                    ...horarios.keys()
                                                                ].forEach(
                                                                    keyHorario => {
                                                                        let binaryDay = daysByBinary(
                                                                            this.props.horarios.get(
                                                                                keyHorario
                                                                            )
                                                                        )[
                                                                            diaSemana
                                                                        ];
                                                                        if (
                                                                            binaryDay.active
                                                                        ) {
                                                                            horarios.set(
                                                                                keyHorario,
                                                                                this.props.horarios.get(
                                                                                    keyHorario
                                                                                ) -
                                                                                    binaryDay.value
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                            } else {
                                                                [
                                                                    ...horarios.keys()
                                                                ].forEach(
                                                                    keyHorario => {
                                                                        let binaryDay = daysByBinary(
                                                                            this.props.horarios.get(
                                                                                keyHorario
                                                                            )
                                                                        )[
                                                                            diaSemana
                                                                        ];
                                                                        if (
                                                                            !binaryDay.active
                                                                        ) {
                                                                            horarios.set(
                                                                                keyHorario,
                                                                                this.props.horarios.get(
                                                                                    keyHorario
                                                                                ) +
                                                                                    binaryDay.value
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                            }
                                                            this.setState({
                                                                horarios
                                                            });
                                                        }}
                                                    >
                                                        {!columnActive ? (
                                                            <Link
                                                                style={{
                                                                    cursor:
                                                                        'pointer'
                                                                }}
                                                            />
                                                        ) : (
                                                            <LinkOff
                                                                style={{
                                                                    cursor:
                                                                        'pointer'
                                                                }}
                                                            />
                                                        )}
                                                    </Button>
                                                </TableCell>
                                            );
                                        }
                                    )}
                                </TableRow>
                            )}

                            {_.sortBy([...horarios.keys()]).map(
                                (keyHorario, i) => {
                                    console.log(horarios, keyHorario);
                                    let horario = horarios.get(keyHorario);
                                    return (
                                        <TableRow key={i}>
                                            <TableCell
                                                style={stylesTableCellItem}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        if (
                                                            window.confirm(
                                                                'Deseja realmente deletar o horário ' +
                                                                    keyHorario +
                                                                    ' ?'
                                                            )
                                                        ) {
                                                            horarios.delete(
                                                                keyHorario
                                                            );
                                                            this.setState({
                                                                horarios
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <DeleteSweep
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        if (horario === 0) {
                                                            horario = 255;
                                                        } else {
                                                            horario = 0;
                                                        }
                                                        horarios.set(
                                                            keyHorario,
                                                            horario
                                                        );
                                                        this.setState({
                                                            horarios
                                                        });
                                                    }}
                                                >
                                                    {horario === 0 ? (
                                                        <Link
                                                            style={{
                                                                cursor:
                                                                    'pointer'
                                                            }}
                                                        />
                                                    ) : (
                                                        <LinkOff
                                                            style={{
                                                                cursor:
                                                                    'pointer'
                                                            }}
                                                        />
                                                    )}
                                                </Button>
                                            </TableCell>
                                            {Object.keys(
                                                static_values_days
                                            ).map((diaSemana, i2) => {
                                                const active = daysByBinary(
                                                    horario
                                                )[diaSemana].active;
                                                return (
                                                    <TableCell
                                                        style={
                                                            stylesTableCellItem
                                                        }
                                                        key={i2}
                                                    >
                                                        {keyHorario}
                                                        <Checkbox
                                                            style={
                                                                stylesCheckbox
                                                            }
                                                            checked={active}
                                                            onClick={() => {
                                                                if (active) {
                                                                    let horario = horarios.get(
                                                                        keyHorario
                                                                    );
                                                                    horario -=
                                                                        static_values_days[
                                                                            diaSemana
                                                                        ];
                                                                    horarios.set(
                                                                        keyHorario,
                                                                        horario
                                                                    );
                                                                } else {
                                                                    let horario = horarios.get(
                                                                        keyHorario
                                                                    );
                                                                    horario +=
                                                                        static_values_days[
                                                                            diaSemana
                                                                        ];
                                                                    horarios.set(
                                                                        keyHorario,
                                                                        horario
                                                                    );
                                                                }
                                                                this.setState({
                                                                    horarios
                                                                });
                                                            }}
                                                        />
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                }
                            )}
                        </TableBody>
                    </Table>
                </Paper>

                <form onSubmit={event => {
                    event.preventDefault();

                    const { tipoDiaSelecionado, texto } = event.target;

                    const valorBinario = new Map([
                        [`DIA_UTIL`, 62],
                        ['SABADO', 64],
                        ['DOMINGO_FERIADO', 129],
                    ]).get(tipoDiaSelecionado.value)

                    const { horarios } = this.props;
                    texto.value.split(" ").forEach(hora => {
                        horarios.set(hora, valorBinario + (horarios.get(hora) || 0))
                    });

                    this.props.onChange(horarios);

                }} >
                    <Box mt={5} width="100%" display="flex" >
                        <FormControl variant="filled"  style={{width: '200px'}}>
                            <InputLabel htmlFor="filled-age-simple">Tipo do dia</InputLabel>
                            <Select
                                value={this.state.tipoDiaSelecionado}
                                name="tipoDiaSelecionado"
                                onChange={event => this.setState({ [event.target.name] : event.target.value })}
                                input={<FilledInput name="tipoDia" />}
                            >
                                <MenuItem value={`DIA_UTIL`}>DIAS ÚTEIS</MenuItem>
                                <MenuItem value={`SABADO`}>SABADOS</MenuItem>
                                <MenuItem value={`DOMINGO_FERIADO`}>DOMINGOS E FERIADOS</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Inserir Horários Separado por espaço, "
                            style={{ margin: 8 }}
                            placeholder="Exemplo=07:00 07:30 08:30 09:30 10:00 11:00 ...."
                            fullWidth
                            margin="normal"
                            name="texto"
                        />
                        <Button variant="outlined" type="submit">
                            Inserir
                        </Button>

                    </Box>
                </form>

                    
            </React.Fragment>
        );
    }
}
export default HorariosOnibus; // withStyles(styles)
